@use '../../../mixins';
@use '../../colors';

// @function gradientcolor($ {
//     @if length($units) ==1 {
//         @return nth($units, 1);
//     } @else if length($units) == 2 {
//         @return nth($units, 1) nth($units, 2);
//     } @else if length($units) == 4 {
//         @return nth($units, 1) nth($units, 2) nth($units, 3) nth($units, 4);
//     }
// }

.day-container {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: colors.$itemBgColor;
    background-color: var(--item-bg-color);

    .dayLink {
        color: inherit;
        display: block;
        width: 100%;
        text-decoration: none;
    }

    .dayLink:hover {
        background-color: colors.$hoverColor;
        background-color: var(--hover-color);
    }

    .weekday {
        background-color: colors.$itemSecondaryBgColor;
        background-color: var(--item-secondary-bg-color);
        width: auto;
        padding: 5px;
        font-weight: 600;
        border-bottom: 1px solid;
        border-color: colors.$itemBorderColor;
        border-color: var(--item-border-color);
    }

    .dataContainer div {
        .date {
            font-size: 0.9rem;
            font-weight: 500;
            padding: 8px;
        }

        .symb {
            width: 36px;
            height: 36px;
            @include mixins.up('sm') {
                width: 50px;
                height: 50px;
            }
        }
        .rain {
            padding: mixins.spacing(1, 0, 2, 0);

            img {
                display: block;
                margin: 2px auto 6px auto;

                @include mixins.up('sm') {
                    display: inline-block;
                    vertical-align: middle;
                    margin: -1px 4px 0 0;
                }
            }
        }
    }

    div.temp {
        font-size: 0.9rem;
        margin: mixins.spacing(0.5, 1.5, 0, 1.5);

        @include mixins.up('md') {
            margin-left: mixins.spacing(2.5);
            margin-right: mixins.spacing(2.5);

            br {
                display: none;
            }
        }

        .value {
            font-size: 1.1rem;
            font-weight: 600;
        }
    }

    div.wind {
        font-size: 0.9rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        @include mixins.up('md') {
            flex-direction: row;
        }

        .windText {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @include mixins.up('sm') {
                flex-direction: row;
            }
        }

        span {
            font-weight: 600;
            padding-top: mixins.spacing(0.5);
            @include mixins.up('sm') {
                margin-right: 5px;
            }
        }

        img {
            margin-top: mixins.spacing(0.5);
            @include mixins.up('sm') {
                margin: mixins.spacing(1, 0.5, 0, 0);
            }
        }
    }

    .dataContainer {
        > .sunny {
            background: linear-gradient(180deg, rgba(254, 211, 127, 0.75) -10%, rgba(255, 255, 255, 0) 40%);
        }
        > .cloudy {
            background: linear-gradient(180deg, rgba(232, 232, 232, 0.75) -10%, rgba(255, 255, 255, 0) 40%);
        }
        > .drizzle {
            background: linear-gradient(180deg, rgba(90, 216, 228, 0.75) -10%, rgba(255, 255, 255, 0) 40%);
        }
        > .rain {
            background: linear-gradient(180deg, rgba(159, 202, 230, 0.75) -10%, rgba(255, 255, 255, 0) 40%);
        }
        > .heavyrain {
            background: linear-gradient(180deg, rgba(182, 206, 215, 0.75) -10%, rgba(255, 255, 255, 0) 40%);
        }
        > .storm {
            background: linear-gradient(180deg, rgba(192, 157, 233, 0.75) -10%, rgba(255, 255, 255, 0) 40%);
        }
        > .nightclear {
            background: linear-gradient(180deg, rgba(131, 165, 196, 0.75) -10%, rgba(255, 255, 255, 0) 40%);
        }
        > .nightcloudy {
            background: linear-gradient(180deg, rgba(184, 194, 199, 0.75) -10%, rgba(255, 255, 255, 0) 40%);
        }
        > .nightdrizzle {
            background: linear-gradient(180deg, rgba(79, 198, 195, 0.75) -10%, rgba(255, 255, 255, 0) 40%);
        }
        > .nightrain {
            background: linear-gradient(180deg, rgba(118, 177, 206, 0.75) -10%, rgba(255, 255, 255, 0) 40%);
        }
        > .nightheavyrain {
            background: linear-gradient(180deg, rgba(151, 187, 193, 0.75) -10%, rgba(255, 255, 255, 0) 40%);
        }
        > .nightstorm {
            background: linear-gradient(180deg, rgba(157, 154, 204, 0.75) -10%, rgba(255, 255, 255, 0) 40%);
        }
    }
}
