@use '../../mixins';
@use '../colors';

section.notfound {
    width: 100%;
    background-color: colors.$bgColor;
    background-color: var(--bg-color);
    padding: mixins.spacing(2);
    text-align: center;
    font-size: 1rem;

    p {
        margin: mixins.spacing(2, 0);
    }

    a {
        text-decoration: none;
        color: white;
        background-color: #2fb0ff;
        display: inline-block;
        padding: mixins.spacing(1, 2);
        border-radius: 2px;
    }
}
