@use '../../mixins';
@use '../colors';

#longfc {
    z-index: 100;
    user-select: none;
    position: relative;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: var(--item-bg-color);
    margin-top: 1rem;
}

.details {
    padding: mixins.spacing(0, 2, 1, 2);
    background-color: var(--item-bg-color);

    .item {
        background-color: var(--item-bg-color);
    }

    .grid {
        padding: mixins.spacing(1);
        line-height: 1.4rem;
    }

    .value {
        font-weight: 500;
        text-align: right;

        .pred_1 {
            color: green;
        }

        .pred_2 {
            color: #0b9eff;
        }

        .pred_3 {
            color: #e3303d;
        }
    }
}
