@use '../../mixins';
@use '../colors';

$border: rgba(1, 1, 1, 0.12);

.weatherpedia {
    margin-bottom: 5px;
    padding: mixins.spacing(0, 1.5, 2, 1.5);

    background-color: colors.$itemBgColor;
    background-color: var(--item-bg-color);

    @include mixins.up('md') {
        padding: 2% 10%;
        margin: auto;
    }

    img {
        width: 100%;
    }

    .nextArticle {
        order: 6;
        h3 {
            font-size: 1.2rem;
            font-weight: 600;
        }
        a {
            display: block;
            padding: mixins.spacing(2, 1);
            text-decoration: none;
            color: inherit;

            h4 {
                font-size: 1.1rem;
                padding: mixins.spacing(1, 0);
            }
        }
    }

    a {
        &.backLink {
            font-weight: bold;
            color: #2fb0ff;
            text-decoration: none;
            &:focus {
                outline: none;
            }
        }
    }

    article#weatherpedia-article {
        line-height: 1.5;
        text-align: left;

        a {
            color: inherit;
        }

        h1 {
            flex: 100%;
            background-color: colors.$itemBgColor;
            background-color: var(--item-bg-color);
        }

        h2 {
            padding: 0;
        }

        h2:not(.simple) {
            font-size: 1.3rem;
            font-weight: 700;
            color: colors.$textColor;
            color: var(--text-color);
            background-color: colors.$itemBgColor;
            background-color: var(--item-bg-color);
        }

        p,
        ul,
        figure {
            padding: mixins.spacing(1, 0);
        }

        ul {
            list-style-type: disc;
        }

        figure {
            margin: 0;
        }

        p {
            &.updated {
                color: colors.$lightTextColor;
                color: var(--light-text-color);
            }
        }

        table {
            border-collapse: collapse;
            border-color: $border;
            vertical-align: middle;
            margin-bottom: 1rem;

            caption {
                font-weight: 700;
                font-size: 1.1rem;
                padding: mixins.spacing(1, 0);
                text-align: left;
            }

            th,
            td {
                padding: 0.25rem 0.5rem;
            }

            th {
                text-align: left;
            }

            strong {
                font-weight: 700;
            }
        }
    }

    &.listing {
        .item {
            a {
                color: inherit;
                display: block;
                text-decoration: none;
            }
        }

        h2 {
            color: #2fb0ff;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.5;
            padding: mixins.spacing(1, 0);
        }

        p {
            &.intro {
                font-size: 1rem;
                line-height: 1.5;
                text-align: left;
                padding: mixins.spacing(2, 0, 0, 0);
            }

            span {
                color: #2fb0ff;
                font-weight: 600;
            }
        }
    }
}
