@use '../../mixins';

#share {
    display: inline-flex;
    align-items: center;
    line-height: 1;
    padding-left: mixins.spacing(1);

    button.showShare {
        display: none;

        &.visible {
            display: block;
        }

        &.common {
            .share-common {
                display: block;
            }
            .share-ios {
                display: none;
            }
        }

        &.ios {
            .share-common {
                display: none;
            }

            .share-ios {
                display: block;
                position: relative;
                top: -2px;
            }
        }
    }

    ul {
        display: none;

        &.visible {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            min-height: 56px;
            align-items: center;
            justify-content: space-between;
            padding: mixins.spacing(1, 6, 0, 6);
            background-color: var(--appbar-bg-color);

            @include mixins.up('sm') {
                padding-top: mixins.spacing(2);
            }
        }

        li {
            padding: mixins.spacing(0, 0, 1, 0);

            a {
                color: var(--appbar-button-color);
            }

            &.share_cp {
                position: relative;

                .popup {
                    top: 0;
                    left: -150%;
                    opacity: 0;
                    visibility: hidden;
                    position: absolute;
                    cursor: pointer;
                    white-space: nowrap;
                    color: black;
                    background-color: white;
                    border: 1px solid black;
                    padding: mixins.spacing(0.5, 1);
                    border-radius: mixins.spacing(0.5);
                    transition: opacity 0.6s ease-in;

                    &.visible {
                        visibility: visible;
                    }

                    &.show {
                        opacity: 1;
                        transition: opacity 0.8s ease-in;
                    }
                }
            }

            button {
                padding: 0;
            }
        }
    }
}
