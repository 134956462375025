@use '../../../mixins';

.item.warningsteaser {
    color: white;
    cursor: pointer;
    font-weight: 500;
    padding: mixins.spacing(0.5, 1);
    margin: mixins.spacing(0, 0, 1, 0);

    order: 1;

    @include mixins.up('sm') {
        order: 0;
    }

    &.front {
        order: 1;

        @include mixins.up('sm') {
            order: 3;
        }
    }

    &.spot {
        order: 2;

        @include mixins.up('sm') {
            order: 4;
        }

        @include mixins.up('md') {
            order: 5;
        }
    }

    @include mixins.up('sm') {
        margin: mixins.spacing(0, 0.5, 1, 0.5);
    }
    font-size: 0.95rem;

    &.warning_R {
        background-color: #d32f2f;
    }

    &.warning_O {
        background-color: #f57c00;
    }

    &.warning_Y {
        background-color: #ffa000;
    }

    &.warning_NP {
        background-color: #375d99;
    }

    p {
        text-align: center;
        position: relative;

        a {
            color: inherit;
            display: block;
            text-decoration: none;

            img {
                vertical-align: middle;

                &.right {
                    position: absolute;
                    right: 0;
                    top: 0.5rem;
                }
            }
        }
    }
}
