@use '../../mixins';
@use '../colors';

$rain: rgb(47, 176, 255);

.expandable-days {
    width: 100%;
    background-color: colors.$itemBgColor;
    background-color: var(--item-bg-color);
    // margin-bottom: 1rem;

    .legend {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: mixins.spacing(0.5, 2, 0.5, 0.5);

        background-color: colors.$headingBgColor;
        background-color: var(--heading-bg-color);
        color: colors.$headingTextColor;
        color: var(--heading-text-color);
        text-transform: uppercase;
        font-size: 0.7rem;
        @include mixins.up('sm') {
            font-size: 0.8rem;
        }

        .date {
            width: 55px;
            display: block;
            align-self: flex-start;
            @include mixins.up('sm') {
                display: block;
                width: 120px;
            }
        }

        .rest {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;

            div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .conditions {
                min-width: 68px;
                align-self: flex-start;
                @include mixins.up('sm') {
                    min-width: 80px;
                }
                @include mixins.up('md') {
                    align-self: center;
                    min-width: 170px;
                }
            }

            .unit {
                font-size: 0.8em;
                text-transform: none;
            }
        }

        .mobileOnly {
            display: flex;
            @include mixins.up('sm') {
                display: none;
            }
        }

        .desktopOnly {
            display: none;
            @include mixins.up('sm') {
                display: flex;
            }
        }
    }

    .daysWrapper {
        display: flex;
        flex-direction: column;

        a {
            color: inherit;
            background-color: colors.$listBgColor;
            background-color: var(--list-bg-color);
            padding: mixins.spacing(0, 0.5);
            display: block;
            width: 100%;
            text-decoration: none;
        }
        .hours {
            // Should make sure that transitioning max-height works on all platforms w/o affecting layout etc.
            // If not, just use display: none/block to hide and show.
            // display: none;
            opacity: 0.5;
            max-height: 0px;
            transition: opacity 0.25s ease-in;
            transition: max-height 0.33s ease-in-out;
            padding-bottom: 0px;
            overflow: hidden;
        }

        .show {
            // display: block;
            opacity: 1;
            max-height: 3000px;
        }

        .dayColumn {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            border-bottom-style: solid;
            border-bottom-width: 1px;
            border-color: colors.$itemBorderColor;
            border-color: var(--item-border-color);

            &.last {
                border-bottom-style: none;
            }
            .dayRow {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                min-height: 60px;
                padding: mixins.spacing(0.5, 2, 0.5, 0.5);

                font-size: 1rem;

                .expand-icon {
                    z-index: 3;
                    position: absolute;
                    right: 8px;
                    @include mixins.up('sm') {
                        right: 38px;
                    }
                    @include mixins.up('md') {
                        right: 8px;
                    }
                    color: colors.$linkTextColor;
                    color: var(--link-text-color);
                    &.show {
                        transform: rotate(180deg);
                    }
                }
            }

            .description {
                padding: mixins.spacing(0, 0, 0.5, 0.5);
                p {
                    color: colors.$lightTextColor;
                    color: var(--light-text-color);
                }
            }
        }

        @media (hover: hover) {
            .dayColumn:hover {
                background-color: colors.$hoverColor;
                background-color: var(--hover-color);
            }
        }

        .date {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 55px;
            font-size: 0.9rem;
            @include mixins.up('sm') {
                font-size: 1rem;
                width: 120px;
            }
        }

        .dayDataRow {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
            width: inherit;

            font-size: 0.9rem;
            @include mixins.up('sm') {
                font-size: 1rem;
            }
            @include mixins.up('md') {
                width: inherit;
            }

            span {
                text-align: center;
            }

            .conditions {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-self: flex-start;
                align-items: center;
                min-width: 68px;
                @include mixins.up('sm') {
                    min-width: 80px;
                }
                @include mixins.up('md') {
                    justify-content: center;
                    align-self: center;
                    min-width: 170px;
                }
                img {
                    margin-left: 5px;
                    width: 25px;
                    height: 25px;
                    @include mixins.up('sm') {
                        margin-left: 10px;
                        width: 30px;
                        height: 30px;
                    }
                }
            }

            .unit {
                display: none;
                @include mixins.up('sm') {
                    display: inline;
                }
            }

            .winds {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                text-align: end;
                img {
                    margin-right: 8px;
                }
            }
        }
    }

    .winds {
        min-width: 45px;
        text-align: center;
        @include mixins.up('sm') {
            min-width: 55px;
        }
    }

    .precip {
        min-width: 45px;
        text-align: center;
        .colored {
            color: colors.$lightTextColor;
            color: var(--light-text-color);
            &.raining {
                color: $rain;
            }
        }
    }

    .sunshine {
        min-width: 65px;
        text-align: center;
        .timeUnit {
            font-size: 0.7rem;
        }
    }
    @include mixins.up('sm') {
        .winds {
            min-width: 85px;
        }

        .precip {
            min-width: 90px;
        }

        .sunshine {
            min-width: 80px;
        }
    }
}
