@use '../../mixins';

.item.warnings {
    .warning {
        color: white;
        padding: mixins.spacing(2);
        margin: mixins.spacing(0, 0, 3, 0);

        &.warning_none {
            background-color: green;
        }

        &.warning_R {
            background-color: #d32f2f;
        }

        &.warning_O {
            background-color: #f57c00;
        }

        &.warning_Y {
            background-color: #ffa000;
        }

        &.warning_NP {
            background-color: #375d99;
        }

        h3 {
            border: none;
            font-size: 1.1rem;
            margin: 0;
            padding: 0;
        }

        .warningContent {
            display: flex;

            img {
                margin: mixins.spacing(1, 2, 1, 0);
            }

            p {
                padding: mixins.spacing(1, 0);

                &.attr {
                    font-style: italic;
                }
            }
        }
    }
}
