$spacing: 8;

$xsBreakpoint: 0 !default;
$smBreakpoint: 600 !default;

$breakpoints: (
    'xs': $xsBreakpoint,
    'sm': $smBreakpoint,
    // medium-small
    'ms': 720,
    'md': 972,
    'lg': 1280,
    'xl': 1400,
);

@mixin up($breakpoint) {
    @media (min-width: #{map-get($breakpoints, $breakpoint)}px) {
        @content;
    }
}

@function absoluteSpacing($units...) {
    @if length($units) ==1 {
        @return nth($units, 1);
    } @else if length($units) == 2 {
        @return nth($units, 1) nth($units, 2);
    } @else if length($units) == 4 {
        @return nth($units, 1) nth($units, 2) nth($units, 3) nth($units, 4);
    }
}

@function spacing($units...) {
    @if length($units) ==1 {
        @return #{nth($units, 1) * $spacing}px;
    } @else if length($units) == 2 {
        @return #{nth($units, 1) * $spacing}px #{nth($units, 2) * $spacing}px;
    } @else if length($units) == 4 {
        @return #{nth($units, 1) * $spacing}px #{nth($units, 2) * $spacing}px #{nth($units, 3) * $spacing}px #{nth(
                $units,
                4
            ) * $spacing}px;
    }
}
