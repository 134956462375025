@use '../../mixins';
@use '../colors';

#searchField {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2rem;
    width: 100%;
    border: 1px solid colors.$itemBorderColor;
    border: 1px solid var(--item-border-color);
    border-radius: 2px;
    background: none;
    padding: mixins.spacing(1, 1, 1, 7);
    background-color: colors.$itemBgColor;
    background-color: var(--item-bg-color);
    color: colors.$textColor;
    color: var(--text-color);
}

ul#searchResults {
    width: 100%;
    max-height: 20rem;
    overflow-y: auto;
    position: absolute;
    background-color: colors.$searchResultsBgColor;
    background-color: var(--search-results-bg-color);
    color: colors.$textColor;
    color: var(--text-color);
    cursor: pointer;

    &.hidden {
        display: none;
    }

    li {
        padding: mixins.spacing(0.5, 0, 0.5, 1);

        &.result-row {
            display: flex;

            &.selected {
                background-color: colors.$hoverColor;
                background-color: var(--hover-color);
            }

            .icon {
                padding: mixins.spacing(1, 2, 0, 0);
            }

            .name {
                &.locate {
                    padding: mixins.spacing(1, 0, 0, 0);
                }
            }
        }
    }
}
