@use '../../../mixins';
@use '../../colors';

.nowcast {
    order: 1;

    .wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding-top: mixins.spacing(3);

        background-color: rgba(255, 255, 255, 0.3);

        p.large {
            font-size: 3.75rem;
            font-weight: 300;
            line-height: 0.8;
            padding: mixins.spacing(0, 0, 2, 0);
        }

        .row {
            display: flex;
            padding: mixins.spacing(1, 1.5);
            padding-bottom: 0;
            flex-direction: row;
            justify-content: space-between;
            background-color: colors.$bgColor;
            background-color: var(--bg-color);
            @include mixins.up('sm') {
                background-color: colors.$itemBgColor;
                background-color: var(--item-bg-color);
            }

            > div {
                flex: 1;
            }

            &.wx {
                flex-grow: 1;
                display: block;
                margin: mixins.spacing(0, 0, 0, 0);
                padding: mixins.spacing(1, 1.5, 1, 0);
                font-weight: 500;
                text-align: right;
                font-size: 1rem;
            }

            &.details {
                color: colors.$lightTextColor;
                color: var(--light-text-color);
                padding-bottom: mixins.spacing(1);

                em {
                    color: colors.$textColor;
                    color: var(--text-color);
                }
            }

            &.sun {
                margin-top: mixins.spacing(1);
                padding-top: 20px;
                background-image: url(//cache.foreca.net/static/img/daylen.svg);
                background-repeat: no-repeat;
                background-position: 50% 10%;
                background-size: 145px 45px;
            }

            .temp {
                .dewp {
                    color: colors.$lightTextColor;
                    color: var(--light-text-color);

                    em {
                        color: colors.$textColor;
                        color: var(--text-color);
                    }
                }
            }

            .wind {
                text-align: right;

                .gust {
                    font-size: 0.8rem;
                    color: colors.$lightTextColor;
                    color: var(--light-text-color);

                    em {
                        color: colors.$textColor;
                        color: var(--text-color);
                    }
                }
            }
        }
    }
}
