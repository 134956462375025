@use '../../../mixins';
@use '../../colors';

.ski-info-teaser {
    order: 7;

    .wrap {
        background-color: colors.$itemBgColor;
        background-color: var(--item-bg-color);

        h4 {
            padding: mixins.spacing(0, 1);
            a {
                font-weight: 500;
                color: var(--link-teaser-text-color);
                text-decoration: none;

                &:hover {
                    color: var(--link-text-color);
                }
            }
        }

        .columns {
            padding: mixins.spacing(1);

            div {
                p {
                    padding: mixins.spacing(0.25, 0);

                    span {
                        font-weight: 500;
                    }
                }
            }

            @include mixins.up('sm') {
                display: flex;

                div {
                    flex: 1;
                }
            }
        }
    }
}
