@use '../../mixins';
@use '../colors';

.recommend {
    order: 11;
    .imageWrapper {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .pictureWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 130px;
        // margin: mixins.spacing(0, 0, 1, 0);
        // order: 3;
        picture {
            width: 92%;
            height: 90%;
            img {
                height: 100%;
                width: 100%;
            }
        }
        @include mixins.up('sm') {
            height: 180px;
            picture {
                width: 100%;
                height: 85%;
                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }
        @include mixins.up('md') {
            height: 100%;
            picture {
                width: 100%;
                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
    .recommendationImage {
        width: 90%;
        max-width: 500px;
        max-height: 300px;
        padding: mixins.spacing(3, 1, 3, 1);
        align-self: center;
        @include mixins.up('md') {
            max-height: 400px;
        }
    }
    .socialButtonRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 3rem;

        .button {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            height: 3rem;
            min-width: 25px;

            &.fbButton {
                background-color: #4267b2;
            }

            &.xButton {
                background-color: #1da1f2;
            }

            &.emailButton {
                background-color: #aaaaaa;
            }
        }

        a {
            flex: 1;
            text-decoration: none;
        }
    }
}
