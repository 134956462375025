span {
    &.value {
        &.temp_c,
        &.wind_ms,
        &.wind_kmh,
        &.wind_bft,
        &.pres_hpa,
        &.pres_mmhg,
        &.rain_mm,
        &.vis_km,
        &.time_24h {
            display: none;
        }
    }
}
