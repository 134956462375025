@use '../../mixins';
@use '../colors';

.recents {
    order: 8;

    .content {
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom-style: solid;
        border-bottom-color: colors.$itemBorderColor;
        border-bottom-color: var(--item-border-color);
        border-bottom-width: 1px;
        background-color: colors.$listBgColor;
        background-color: var(--list-bg-color);

        &.last {
            border-bottom-style: none;
            border-right-style: none;
        }
    }

    .recentRow {
        width: 100%;
        min-height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: mixins.spacing(0, 1, 0, 1);

        .names {
            span {
              display: none;
            }
            @include mixins.up('sm') {
              span {
                display: inline;
              }
            }
        }

        .rest {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            min-width: 100px;
            @include mixins.up('sm') {
                min-width: 150px;
            }

            .temp {
                font-size: 1rem;
            }
        }

        img {
            width: 30px;
            height: 30px;
        }
    }

    button {
        width: 40px;
        height: 30px;
        margin-left: 5px;
        margin-right: 5px;
        background-color: transparent;
        border-style: none;
        :hover {
            color: colors.$textColor;
            color: var(--text-color);
        }
        span {
            user-select: none;
            color: rgb(211, 211, 211);
            font-size: 1.7rem;
            font-weight: 400;
            text-align: center;
        }
    }

    .recentLink {
        width: 100%;
        text-decoration: none;
        color: inherit;
        :hover {
            background-color: colors.$hoverColor;
            background-color: var(--hover-color);
        }
    }
}
