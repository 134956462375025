@use '../../../mixins';

.airpressure {
    order: 8;

    .wrap {
        height: 100%;

        .legend {
            display: flex;
            justify-content: center;

            div {
                display: flex;
                align-items: center;
                font-size: 0.8em;
                margin-right: mixins.spacing(4);

                span {
                    width: mixins.spacing(2.5);
                    height: mixins.spacing(0.75);
                    border-radius: 5px;
                    background-color: #d55e00;
                    margin-right: 0.3em;

                    &.pres {
                        background-color: #1375f7;
                    }
                }
            }
        }

        .mg-wrap {
            position: relative;
            text-align: center;
            padding: mixins.spacing(1.5, 0, 0, 0);

            #airpressure-mg {
                overflow: scroll;
                overflow-x: auto;
                overflow-y: hidden;
                position: relative;
                z-index: 100;
                user-select: none;
                padding-right: 1rem;
                -webkit-overflow-scrolling: touch;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            }

            .scale {
                top: 65px;
                width: 40px;
                height: 240px;
                position: absolute;
            }

            #airpressure-scale-left {
                left: -6px;

                @include mixins.up('md') {
                    left: -2px;
                }
            }

            #airpressure-scale-right {
                right: -20px;

                @include mixins.up('md') {
                    right: 2px;
                }
            }
        }
    }
}
