@use '../../mixins';
@use '../colors';

h1 {
    padding: mixins.spacing(5, 0.5, 0, 0.5);
    font-weight: 400;

    @include mixins.up('sm') {
        padding: mixins.spacing(1, 1);
    }
}

.country,
.popular {
    .details {
        padding: mixins.spacing(1);
    }
}

.item {
    &.local {
        order: 1;
        margin-top: 1rem;
        @include mixins.up('sm') {
            margin-top: 0rem;
        }
        h2,
        section,
        div {
            width: 100%;
        }
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: colors.$lightBgColor;
        background-color: var(--light-bg-color);
        @include mixins.up('sm') {
            background-color: transparent;
        }
        .linkRow {
            justify-self: flex-end;
        }
        .wrap {
            background-color: transparent;
        }
    }
}

.country {
    order: 2;
    .wrap {
        background-color: colors.$bgColor;
        background-color: var(--bg-color);
    }

    .countryMap {
        width: 100%;
    }
}

.popular {
    order: 6;
}

.linkRow {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: colors.$itemBorderColor;
    border-top-color: var(--item-border-color);
    @include mixins.up('sm') {
        flex-direction: row;
        align-items: center;
    }
    justify-content: space-between;
    padding: mixins.spacing(0, 0.5, 0.8, 0.5);
    background-color: colors.$listBgColor;
    background-color: var(--list-bg-color);

    &.single {
        @include mixins.up('md') {
            justify-content: flex-end;
        }
    }

    .severeLink {
        text-align: end;
    }

    a {
        text-decoration: none;
        color: colors.$linkTextColor;
        color: var(--link-text-color);

        font-size: 0.9rem;
        font-weight: 700;
        svg {
            position: relative;
            // bottom: 0px;
            top: 0.45rem;
        }
    }

    button {
        background-color: colors.$listBgColor;
        background-color: var(--list-bg-color);
        border-style: none;
        padding: 0;
        font-weight: 700;
        font-size: 0.9rem;
        em {
            color: #3f77c9;
            font-size: 0.9rem;
            font-weight: 700;
        }
        svg {
            position: relative;
            top: 0.45rem;
        }
    }
}

#videoContainer {
    position: relative;
    cursor: pointer;

    .play {
        position: absolute;
        width: 90px;
        height: 90px;
        top: calc(50% - 50px);
        left: calc(50% - 45px);
    }

    .tapToPlay {
        color: white;
        font-size: 1rem;
        font-weight: 600;
        text-shadow: 1px 1px #000000;
        position: absolute;
        width: 100px;
        height: 100px;
        top: calc(64%);
        left: calc(50% - 50px);
    }
}
