@use '../../mixins';
@use '../colors';

$border: rgba(1, 1, 1, 0.12);

.article {
    order: 10;

    &.top {
        order: 1;
    }

    .lead {
        padding: mixins.spacing(1, 1, 1, 1);
    }

    h2 {
        flex: 100%;
        background-color: colors.$itemBgColor;
        background-color: var(--item-bg-color);
    }

    #body {
        padding: mixins.spacing(1, 1, 1, 1);

        img {
            max-width: 100%;
        }

        h2 {
            background-color: white;
            color: rgb(50, 50, 93);
            font-weight: 500;
            font-size: 1.5rem;
            padding: 0px;
        }

        p {
            padding: mixins.spacing(1, 0, 1, 0);
        }

        ul {
            list-style-type: disc;
            list-style-position: inside;
            padding: mixins.spacing(1);
        }

        figure {
            margin: 0px;
            margin-bottom: 1rem;
        }

        div + h2 {
            margin-top: mixins.spacing(2);
        }

        table {
            border-collapse: collapse;
            border-color: $border;
            vertical-align: middle;
            margin-bottom: 1rem;

            caption {
                font-weight: 700;
                font-size: 1.1rem;
                padding: mixins.spacing(1, 0);
                text-align: left;
            }

            th,
            td {
                padding: 0.25rem 0.5rem;
            }

            th {
                text-align: left;
            }

            strong {
                font-weight: 700;
            }
        }
    }
}
