@use '../../../mixins';
@use '../../colors';

.today-container {
    order: 3;

    .wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: colors.$itemBgColor;
        background-color: var(--item-bg-color);

        h1 {
            font-weight: 600;
            font-size: 1.1em;
            padding: 4px 8px;
            margin: 0;
        }

        h2.simple {
            font-weight: 500;
            font-size: 0.85rem;
            padding: 8px 0px 0px 0px;
            margin: 0px 8px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }

        .wx {
            font-size: 0.8rem;
            padding: mixins.spacing(1);
            color: colors.$lightTextColor;
            color: var(--light-text-color);
            line-height: 1.15rem;
        }

        .hours {
            display: flex;
            flex-grow: 1;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: mixins.spacing(0.5);

            .hour {
                h3 {
                    border: 0;
                    margin: 0;
                    padding: 0;
                    font-weight: 700;
                    font-size: 0.8rem;
                }

                flex: 1;
                font-size: 0.9rem;
                text-align: center;
                padding-top: mixins.spacing(0.5);

                img {
                    display: inline-block;
                    margin: mixins.spacing(1, 0);
                }

                p {
                    font-weight: 500;
                }
            }

            a {
                color: inherit;
                display: block;
                width: 100%;
                text-decoration: none;
                :hover {
                    background-color: colors.$hoverColor;
                    background-color: var(--hover-color);
                }
            }
        }
    }
}
