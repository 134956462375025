@use '../../mixins';

.header-and-feedback {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap-reverse;

    @include mixins.up("sm") {
        flex-direction: row;
        align-items: center;
    }

    .feedback {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        margin: 8px;

        .star {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
        }
    
        svg {
            fill: cornflowerblue;
            width: 3rem;
            height: 3rem;
        }
        
        span {
            color: white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-weight: bold; /* Optional: to make the text bold */
        }

        a {
            color: var(--link-text-color);
        }
    }
}

$rain: #0073bc;
$bright-red: rgb(246, 69, 69);
$missing: rgb(165, 165, 165);
$symbol-gray: rgb(130, 130, 130);

#calendarComponent {
    width: 100%;
    margin-bottom: 8px;
    background-color: var(--bg-color);

    h2, h3, h4 {
        font-weight: bold;
        margin: 8px 4px;
        padding: 0;
    }

    p {
        margin: 0 4px;
    }

    h2 {
        background-color: transparent;
        color: var(--text-color);
        font-size: 1.5em;
    }

    h3 {
        font-size: 1.17em;
        border-bottom: none;
    }

    .month-title {
        display: none;

        @include mixins.up("md") {
            display: block;
        }
    }

    .month-select {
        background-color: var(--bg-color);

        .month-bar {
            display: none; // Hide by default
        
            @include mixins.up("md") {
                display: grid;
                grid-template-columns: repeat(13, minmax(0, 1fr)); // Create 13 equal-width columns that can shrink
                background-color: var(--item-secondary-bg-color);
                margin: 8px 0;
                padding: 0 4px;
            
                .month {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    font-size: 0.8rem;
                    cursor: pointer;
                    user-select: none;
                    margin: 4px 0;
            
                    &.selected {
                        background-color: $rain;
                        color: white;
                        font-weight: 700;
                        border-radius: 2px;
                    }
            
                    &.current {            
                        font-weight: 700;
                    }
                }
            }
        }
    
        .month-dropdown {
            background-color: var(--item-secondary-bg-color);
            color: var(--text-color);
            width: 100%;
            padding: 10px;
            border: 1px solid var(--item-border-color);
            border-radius: 5px;
            font-size: 1rem;
            margin: 8px 0 8px 0;
    
            @include mixins.up("md") {
                display: none; // Hide the dropdown on larger screens
            }
        }
    }

    .calendar-grid {
        margin: 8px 0 8px 0;
        display: grid;
        grid-template-columns: repeat(7, minmax(0, 1fr)); /* Ensure columns are flexible */
        gap: 1px; /* Gap between the columns */
        background-color: var(--bg-color);

        @include mixins.up("sm") {
            gap: 2px;
        }
        @include mixins.up("ms") {
            gap: 4px;
        }

        .weekdays, .calendar-days {
            display: contents;

            .weekday {
                text-align: center;
            }
        }

        .day {
            height: 110px;
            background-color: var(--item-bg-color);
            border: 1px solid var(--item-border-color);
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow:   visible;

            @include mixins.up("sm") {
                height: 170px;
            }

            &:hover {
                outline: 1px solid $rain;
                outline-offset: -1px;
            }

            &.calendar-selected {
                outline: 2px solid $rain;
                outline-offset: -1px;
            }

            .day-number {
                width: 20px;
                height: 20px;
                line-height: 20px;
                margin: 4px 0;

                font-size: 0.75rem;
                font-weight: 700;
                display: flex;
                justify-content: center;
                align-items: center;

                @include mixins.up("sm") {
                    font-size: 1.2rem;
                    margin: 0.5rem 0;
                    width: 30px;
                    height: 30px;
                }

                background-color: rgb(234, 234, 234);
                border-radius: 50%;

                body.theme-dark & {
                    background-color: rgb(105, 105, 105);
                    color: white;

                    &.today {
                        background-color: $bright-red;
                        color: white;
                    }
                }

                &.today {
                    background-color: $bright-red;
                    color: white;
                }
            }
            

            .day-content {
                align-self: stretch;
                flex-grow: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;
                margin-bottom: 8px;

                .weather-symbol {
                    width: 15px;
                    @include mixins.up("sm") {
                        width: 20px;
                    }                    
                    @include mixins.up("md") {
                        width: 35px;
                    }
                }

                .icon-container {
                    position: relative;
                    width: 15px;
                    height: 15px;

                    @include mixins.up("sm") {
                        width: 20px;
                        height: 20px;
                    }

                    .observation-symbol, .avg-symbol {
                        width: 100%;
                        height: 100%;
                        fill:$symbol-gray;
                        stroke: $symbol-gray;
                    }

                    .tooltip {
                        font-size: 0.8rem;
                        position: absolute;
                        bottom: 100%;
                        left: 50%;
                        transform: translateX(-50%);
                        background-color: var(--item-bg-color);
                        padding: 5px;
                        border: 1px solid var(--item-border-color);
                        border-radius: 3px;
                        white-space: nowrap;
                        display: none;
                        transition: opacity 0.2s;
                    }

                    &:hover .tooltip {
                        display: block;
                    }
                }

                .max-min-hor {
                    display: none;
                    width: 100%;
                    grid-template-columns: 1fr auto 1fr;
                    align-items: center;
                    justify-items: center;

                    @include mixins.up("md") {
                        display: grid;
                    }

                    .right {
                        width: 100%;
                        text-align: left;
                    }

                    .left {
                        width: 100%;
                        text-align: right;
                    }
                    
                    .divider {
                        margin: 0 0.4rem; /* Adjust as needed */
                    }
                }

                @include mixins.up("md") {
                    .max-min-vert {
                        display: none;
                    }
                }

                .min-temp, .max-temp, .max-min-hor{
                    font-size: 0.75rem;
                    @include mixins.up("sm") {
                        font-size: 1.2rem;
                    }

                    &.missing {
                        color: $missing;
                    }
                }

                .max-temp {
                    font-weight: 700;
                }        
            
                .rain-info {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 4px;
                    text-shadow: 0px 0px 4px var(--item-bg-color), 0px 0px 4px var(--item-bg-color), 0px 0px 4px var(--item-bg-color), 0px 0px 4px var(--item-bg-color);
                
                    .rain-icon {
                        display: none;
                        filter: drop-shadow(0px 0px 3px var(--item-bg-color)) drop-shadow(0px 0px 3px var(--item-bg-color));
                    }
                
                    @include mixins.up("sm") {
                        .rain-icon {
                            display: block;
                            width: 10px;
                            fill: $rain;
                            &.missing {
                                fill: $missing;
                            }
                        }
                    }
                }

                .rain {
                    max-width: 150%;
                    font-size: 0.75rem;
                    text-wrap: nowrap;
                    color: $rain;
                    
                    .unit {
                        font-size: 0.5rem;
                    }
                    @include mixins.up("sm") {
                        font-size: 1rem;
                        .unit {
                            font-size: 0.8rem;
                        }
                    }

                    &.missing {
                        color: $missing;
                    }

                    body.theme-dark & {
                        color: white;
                        &.missing {
                            color: $missing;
                        }
                    }
                }
            }
        }
    }

    .day-summary {
        grid-column: span 7;
        background-color: var(--item-bg-color);
        padding: 10px;
        border: 1px solid var(--item-border-color);
        border-radius: 5px;
        position: relative;

        .summary-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .close-button {
                background: none;
                border: none;
                font-size: 2.5rem;
                cursor: pointer;
                color: $rain;

                &:hover {
                    color: darken($rain, 10%);
                }
            }
        }

        .align-left {
            text-align: left;
        }

        table {
            width: 100%;
            border-collapse: collapse;
            margin-top: 10px;
        
            th, td {
                border: 1px solid var(--item-border-color);
                padding: 4px 6px;
                text-align: center;
            }
        
            th {
                background-color: var(--item-secondary-bg-color);
            }
        
            td {
                background-color: var(--item-bg-color);
            }

            th:first-child, td:first-child {
                width: 50%;
            }
        
            th:nth-child(n+2), td:nth-child(n+2) {
                width: 16.66%;
            }
        
            .temp {
                &.max {
                    font-weight: 700;
                }
            }
        
            @include mixins.up("sm") {
                .temp {
                    font-size: 1.2rem;
                }
                .rain {
                    font-size: 1rem;
                }
            }
        
            .temp, .rain {
                &.missing {
                    color: $missing;
                }
            }
        
            .forecast-link {
                color: var(--link-text-color);
            }
        
            .rain-info {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 4px;
                img {
                    vertical-align: middle;
                    width: 12px;
                }
            }
        
            .icon-row {
                display: flex;
                flex-direction: row;
                gap: 1rem;
                justify-content: flex-start;
                align-items: center;
        
                .tooltip {
                    display: none;
                }
        
                .icon-container {
                    width: 20px;
                    height: 20px;
                    display: none;
        
                    @include mixins.up("sm") {
                        display: block;
                    }
                }
                
                .observation-symbol, .avg-symbol {
                    width: 20px;
                    height: 20px;
                    fill: $symbol-gray;
                    stroke: $symbol-gray;
                }
            }
        }

        p {
            margin-top: 1rem;
        }
    }

    .info {
        background-color: var(--item-bg-color);
        padding: 4px;
        margin: 8px 0;
        border: 1px solid var(--item-border-color);
        border-radius: 5px;

        h3 {
            margin: 4px 4px;
        }

        .icon-row {
            margin-left: 10px;
            display: flex;
            flex-direction: row;
            gap: 8px;
            cursor: pointer;

            .info-icon {
                width: 1rem;
                fill: var(--text-color);
            }

            .info-icon-path {
                fill: var(--item-bg-color);
            }

            .collapsible-icon {
                width: 1rem;
                fill: var(--text-color);
            }
        }
    }

    .month-summary {
        margin: 16px 0;

        table {
            border-collapse: collapse;
            margin-top: 10px;
            background-color: var(--item-bg-color);
        }
    
        th, td {
            border: 1px solid var(--item-border-color);
            padding: 4px 6px;
            text-align: left;
        }
    
        th {
            background-color: var(--item-secondary-bg-color);
            font-weight: bold;
        }

        .centered-text {
            text-align: center;
        } 
    
        .rain_mm, .rain_in {
            margin-right: 10px;
        }
    }

    .text-body {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin: 8px 4px;

        .icon-container {
            width: 20px;
            height: 20px;

            .observation-symbol, .avg-symbol, .symbol-icon {
                width: 20px;
                height: 20px;
                fill:$symbol-gray;
                stroke: $symbol-gray;
            }

            .tooltip {
                display: none;
            }
        }
    }

    .legend {
        margin: 8px 8px;
        width: fit-content;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .gradient {
            position: relative;
            height: 15px;
            background: var(--item-bg-color);
        
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, 
                    rgba(38, 117, 201, 0.75), 
                    rgba(255, 255, 255, 0.75),
                    rgba(255, 60, 0, 0.75), 
                );
            }
        }

        .legend-labels {
            display: flex;
            justify-content: space-between;
        }
    }

    .videos {
        margin: 8px 0;
        display: grid;
        grid-template-rows: repeat(2, 1fr); /* Create two equal columns */
        gap: 8px; /* Gap between the columns */

        @include mixins.up("ms") {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: 1fr;
        }
    
        .video {
            aspect-ratio: 16 / 9;
            width: 100%;
            border: 0;
        }
    }
}