@use '../../../mixins';
@use '../../colors';

.daily {
    order: 3;

    .wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    .dayrow {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        background-color: transparent;

        .daycontainer {
            max-width: 20%;
            text-align: center;

            h3 {
                border: 0;
                font-size: 0.9rem;
                font-weight: 500;
                padding-top: mixins.spacing(1);
                @include mixins.up('md') {
                    padding-top: mixins.spacing(1);
                }
            }

            a {
                display: block;
                text-decoration: none;
                color: inherit;
                &:hover {
                    background-color: rgba(0, 0, 0, 0.03);
                }

                .symb {
                    padding: mixins.spacing(1.5, 0);
                    @include mixins.up('md') {
                        padding: mixins.spacing(2, 0);
                    }
                }

                .temp {
                    font-weight: 300;
                    font-size: 0.9rem;

                    padding: mixins.spacing(1.5, 0);
                    @include mixins.up('sm') {
                        padding: mixins.spacing(1.5, 0);
                    }

                    em {
                        display: block;
                        font-weight: 500;
                    }
                }

                .tempmax {
                    em {
                        font-size: 1.1rem;
                    }
                }

                .tempmin {
                    em {
                        font-size: 0.95rem;
                    }
                }

                .wind {
                    padding-top: mixins.spacing(1.5);

                    em {
                        display: block;
                        font-weight: 700;
                    }
                }

                .prec {
                    padding-top: mixins.spacing(2);

                    img {
                        display: block;
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}
