@use '../../../mixins';
@use '../../colors';

.ski-info-teaser {
    order: 7;

    .wrap {
        background-color: colors.$itemBgColor;
        background-color: var(--item-bg-color);
        padding: mixins.spacing(1);

        li {
            a {
                font-weight: 500;
                color: var(--link-teaser-text-color);
                text-decoration: none;

                &:hover {
                    color: var(--link-text-color);
                }
            }
        }

        ul {
            @include mixins.up('sm') {
                display: flex;
                flex-wrap: wrap;

                li {
                    flex: 50%;
                    padding: mixins.spacing(0.25, 0);
                }
            }
        }
    }
}
