$bgColor: #f5f9fc;
$lightBgColor: rgba(255, 255, 255, 0.4);

$headingBgColor: #2fb0ff;
$itemBgColor: #fff;
$listBgColor: #fff;
$itemSecondaryBgColor: rgb(242, 242, 242);
$itemBorderColor: rgba(1, 1, 1, 0.12);
$hoverColor: #eee;
$mapBgColor: #fff;

$linkBgColor: #fff;
$linkTextColor: #3f77c9;
$linkTeaserTextColor: #3f77c9;

$textColor: #32325d;
$lightTextColor: #999999;
$reverseTextColor: #fff;
$headingTextColor: #fff;

$appbarBgColor: #fff;
$appbarButtonColor: #32325d;

$quicklinksBgColor: #eaebea;
$bottomNaviBgColor: #fff;
$footerBgColor: #fff;
$mgScaleBgColor: rgba(255, 255, 255, 0.75);

$searchResultsBgColor: rgba(255, 255, 255, 0.95);

$buttonActiveColor: $reverseTextColor;
$buttonOutlineColor: rgba(0, 0, 0, 0.3);

$checkedBgColor: #2fb0ff;

$tempCold: #00adef;
$tempWarm: #e3303d;
