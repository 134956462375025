@use '../../mixins';
@use '../colors';

.item.observation-history {
    position: relative;
    background-color: colors.$itemBgColor;
    background-color: var(--item-bg-color);

    .header {
        display: flex;
        margin: mixins.spacing(1, 0);

        form {
            margin: mixins.spacing(0, 1, 0, 0);
        }

        select,
        input[type='text'] {
            padding: mixins.spacing(0.5, 1);
        }

        #stationSelect {
            width: 100%;
        }
    }

    .scale {
        top: 150px;

        @include mixins.up('md') {
            top: 115px;
        }

        &#scale-right {
            right: 0;
        }
    }

    .scroll-view {
        z-index: 100;
        overflow: scroll;
        overflow-x: auto;
        overflow-y: hidden;
        user-select: none;
        touch-action: manipulation;
        -webkit-user-select: none;
        -webkit-overflow-scrolling: touch;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        scrollbar-width: thin;

        @include mixins.up('sm') {
            width: 100%;
        }
    }

    #showMonth {
        font-size: 0.9rem;
        text-align: center;
        padding: mixins.spacing(0, 0, 1, 0);
        cursor: pointer;
    }
}

#details {
    .datetime {
        padding: mixins.spacing(1, 0, 0, 3);
    }

    .alignCenter {
        display: flex;
        align-items: center;
        height: 100;
    }

    .wind {
        flex-direction: column;
        @include mixins.up('xs') {
            flex-direction: row;
        }
    }

    &.rain {
        padding-left: mixins.spacing(3);
        @include mixins.up('xs') {
            padding-left: mixins.spacing(5);
        }
    }
}
