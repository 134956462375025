@use '../../mixins';
@use '../colors';

.gridContainer .item.meteogram {
    position: relative;
    background-color: colors.$itemBgColor;
    background-color: var(--item-bg-color);
}

.meteogram {
    .timewrap {
        padding: mixins.spacing(1.5, 0, 0.5, 2.5);

        em {
            font-weight: 600;
        }
    }
}

#details {
    display: flex;
    flex-wrap: wrap;
    margin-top: mixins.spacing(1);
    padding-bottom: mixins.spacing(1);

    .value {
        font-weight: 500;
        white-space: nowrap;
    }

    .datetime {
        padding-left: mixins.spacing(3);
    }

    .prec {
        padding-left: mixins.spacing(3);

        @include mixins.up('md') {
            padding-left: mixins.spacing(5);
        }
    }

    .wind {
        display: flex;
        align-items: center;
        flex-direction: column;

        @include mixins.up('md') {
            flex-direction: row;
        }
    }

    .pad {
        // padding-top: mixins.spacing(2);
        @include mixins.up('md') {
            padding-top: 0;
        }
    }

    .large {
        font-size: 1.5rem;
    }
}

.meteogram-legend {
    display: flex;
    align-items: center;
    padding: mixins.spacing(0, 0, 1, 1);
    flex-wrap: wrap;

    .precbar {
        display: inline-block;
        width: 25px;
        height: 8px;
        margin: mixins.spacing(0, 1, 0, 2);

        &.showers {
            background: url(//cache.foreca.net/static/i/mg/dotbg.png);
        }

        &.precipitation {
            background-color: #5499f7;
        }
    }

    .mg-updated {
        flex: 100%;
        text-align: right;
        padding: mixins.spacing(0, 1, 0, 0);
        color: colors.$lightTextColor;
        color: var(--light-text-color);

        @include mixins.up('sm') {
            flex: 1;
        }
    }
}
