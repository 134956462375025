@use '../../mixins';
@use '../colors';

.todaycast {
    flex: 1;
    flex-grow: 1;
    width: 100%;

    .wrap {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: mixins.spacing(1, 0, 2, 0);
        @include mixins.up('sm') {
            padding: mixins.spacing(5, 2, 2, 2);
        }
        @include mixins.up('md') {
            padding: mixins.spacing(4, 0, 2, 0);
        }

        background-color: rgba(255, 255, 255, 0.3);

        p.large {
            font-size: 3rem;
            font-weight: 300;
        }

        .row {
            display: flex;
            padding: mixins.spacing(1, 1.5);
            padding-bottom: 0;
            flex-direction: row;
            justify-content: space-between;
            background-color: transparent;
            @include mixins.up('md') {
                background-color: colors.$itemBgColor;
                background-color: var(--item-bg-color);
            }

            > div {
                flex: 1;
            }

            &.wx {
                flex-grow: 1;
                display: block;
                margin: mixins.spacing(0, 0, 0, 0);
                padding: mixins.spacing(0, 1.5, 0.5, 0);
                font-weight: 500;
                text-align: right;
                font-size: 1rem;
            }

            .symb {
                display: flex;
                // flex: 0;
                justify-content: center;
                align-items: flex-start;
                padding-top: 0.5rem;
            }

            &.details {
                color: colors.$textColor;
                color: var(--text-color);

                p.medium {
                    font-size: 2rem;
                    .value {
                        font-weight: 300;
                    }
                }
                span.unit {
                    font-size: 1rem;
                }
                em {
                    color: colors.$textColor;
                    color: var(--text-color);
                }
                .rain {
                    text-align: end;
                }

                .mid {
                    text-align: center;
                }
            }

            .wind {
                text-align: right;

                .gust {
                    font-size: 0.8rem;
                    color: colors.$lightTextColor;
                    color: var(--light-text-color);
                }
            }
        }
    }
}
