@use '../../mixins';
@use '../colors';

.halloween {
    h1,
    h2,
    span.temp {
        color: rgb(255, 154, 1);
    }

    .halloweenHeader {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        width: 100%;
        margin: mixins.spacing(0, 0.5, 0, 0.5);
        background-color: black;
        picture {
            width: 100%;
            img {
                height: 100%;
                width: 100%;
            }
        }
        @include mixins.up('sm') {
            background: linear-gradient(90deg, rgb(175, 175, 175) -10%, var(--item-bg-color) 32%);
            height: 120px;
            margin: mixins.spacing(0, 0.5, 1, 0.5);
            picture {
                width: 60%;
                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }
        @include mixins.up('md') {
            picture {
                width: 80%;
                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }

    .nowcast {
        .wrap {
            padding-top: 0px;
            .wx {
                color: rgb(255, 154, 1);
            }
        }
    }

    .dayHours {
        order: 5;
    }
    .recommend {
        order: 3;
        @include mixins.up('md') {
            display: flex;
            flex: 1;
            flex-grow: 1;
            max-width: 33.333333%;
            flex-basis: 33.333333%;
            .wrap {
                .socialButtonRow,
                h2 {
                    width: 100%;
                }
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .socialButtonRow {
                    height: 2rem;
                    .button {
                        height: 2rem;
                    }
                }
            }
        }
    }
    .hourList {
        order: 5;
        width: 100%;
    }
}

.spot {
    .halloweenTeaser {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 110px;
        width: 100%;
        margin: mixins.spacing(0, 0, 1, 0);
        background-color: black;
        order: 3;
        picture {
            width: 100%;
            img {
                height: 100%;
                width: 100%;
            }
        }
        @include mixins.up('sm') {
            order: 6;
            height: 120px;
            picture {
                width: 100%;
                height: 100%;
                img {
                    height: 100%;
                    width: 100%;
                }
            }
        }
    }
}
