@use '../../mixins';
@use '../colors';

.weekends {
    width: 100%;

    h4 {
        font-size: 1rem;
        padding: mixins.spacing(1.5, 0, 0, 0.5);
        background-color: colors.$bgColor;
        background-color: var(--bg-color);
    }
}
