@use '../../../mixins';

.fwTeaser {
    position: fixed;
    left: 0;
    right: 0;
    bottom: -100px;
    z-index: 100000;
    margin: 0;

    transition-property: bottom;
    transition-duration: 2s;

    .link {
        flex-grow: 1;
        font-weight: 500;
        font-size: 1rem;

        a {
            width: 100%;
            cursor: pointer;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            padding: mixins.spacing(1, 4);
            color: white;
            background-color: #2fb0ff;

            @include mixins.up('md') {
                padding: mixins.spacing(2, 0);
            }
        }
    }

    .dismiss {
        position: absolute;
        top: 6px;
        right: 6px;

        @include mixins.up('md') {
            top: 10px;
            right: 10px;
        }
    }
}
