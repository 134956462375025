@use '../../mixins';
@use '../colors';

$rain: rgb(47, 176, 255);

.brief .legend {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: mixins.spacing(0.5, 0.5, 0.5, 0.5);

    background-color: colors.$headingBgColor;
    background-color: var(--heading-bg-color);
    color: colors.$headingTextColor;
    color: var(--heading-text-color);
    text-transform: uppercase;
    font-size: 0.7rem;
    @include mixins.up('sm') {
        font-size: 0.8rem;
    }

    .locationDescription {
        width: 0;
        display: none;
        @include mixins.up('md') {
            display: block;
            width: 200px;
        }
    }

    .rest {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;

        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .currentWeather {
            min-width: 50px;
            align-self: flex-start;
            @include mixins.up('sm') {
                min-width: 80px;
            }
            @include mixins.up('md') {
                align-self: center;
                min-width: 130px;
            }
        }

        .unit {
            font-size: 0.8em;
            text-transform: none;
        }
    }

    .mobileOnly {
        display: flex;
        @include mixins.up('sm') {
            display: none;
        }
    }

    .desktopOnly {
        display: none;
        @include mixins.up('sm') {
            display: flex;
        }
    }
}

.locationsWrapper {
    display: flex;
    flex-direction: column;
    // margin: mixins.spacing(0.5, 0);

    a {
        color: inherit;
        background-color: colors.$listBgColor;
        background-color: var(--list-bg-color);
        padding: mixins.spacing(0, 0.5);
        display: block;
        width: 100%;
        text-decoration: none;
    }

    .locationRow {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        min-height: 45px;
        padding: mixins.spacing(0.5, 0, 0.5, 0);
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-color: colors.$itemBorderColor;
        border-color: var(--item-border-color);

        font-size: 1rem;
        @include mixins.up('md') {
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }

        &.last {
            border-bottom-style: none;
        }
    }

    .locationName {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 200px;
        padding-bottom: 0.4rem;
        align-self: flex-start;
        @include mixins.up('md') {
            align-self: center;
            padding-bottom: 0rem;
        }
    }

    .dataRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        width: 100%;

        font-size: 0.9rem;
        @include mixins.up('sm') {
            font-size: 1rem;
        }
        @include mixins.up('md') {
            width: inherit;
        }

        span {
            text-align: center;
        }

        div {
            &.current {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-self: flex-start;
                align-items: center;
                min-width: 50px;
                @include mixins.up('sm') {
                    min-width: 80px;
                }
                @include mixins.up('md') {
                    justify-content: center;
                    align-self: center;
                    min-width: 130px;
                }
                img {
                    margin-left: 5px;
                    width: 25px;
                    height: 25px;
                    @include mixins.up('sm') {
                        margin-left: 10px;
                        width: 30px;
                        height: 30px;
                    }
                }
            }

            &.unit {
                display: none;
                @include mixins.up('sm') {
                    display: inline;
                }
            }

            &.wind {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                text-align: end;
                img {
                    margin-right: 8px;
                }
            }
        }
    }
}

.brief {
    div {
        &.high {
            min-width: 35px;
            text-align: center;
        }

        &.low {
            min-width: 35px;
            text-align: center;
        }

        &.wind {
            min-width: 45px;
            text-align: center;
        }

        &.precip {
            min-width: 45px;
            text-align: center;
            .colored {
                color: colors.$lightTextColor;
                color: var(--light-text-color);
                &.raining {
                    color: $rain;
                }
            }
        }

        &.sunshine {
            min-width: 65px;
            text-align: center;
            .timeUnit {
                font-size: 0.7rem;
            }
        }
    }
}
@include mixins.up('sm') {
    .brief {
        div {
            &.high {
                min-width: 40px;
            }

            &.low {
                min-width: 40px;
            }

            &.wind {
                min-width: 70px;
            }

            &.precip {
                min-width: 65px;
            }

            &.sunshine {
                min-width: 80px;
            }
        }
    }
}
