@use '../../../mixins';
@use '../../colors';

.meteogramteaser {
    order: 3;

    .wrap {
        background-color: colors.$itemBgColor;
        background-color: var(--item-bg-color);
    }

    @include mixins.up('sm') {
        order: 6;
    }
}

.scale {
    top: 75px;
    width: 24px;
    height: 225px;
    z-index: 200;
    position: absolute;
    background-color: colors.$mgScaleBgColor;
    background-color: var(--mg-scale-bg-color);

    @include mixins.up('md') {
        top: 88px;
        height: 305px;
    }
}

#scale-left {
    left: 0;
}

#scale-right {
    right: 0;
    @include mixins.up('md') {
        right: 6px;
    }
}

.swiper-wrapper {
    @include mixins.up('ms') {
        .swiper-slide {
            width: 960px;
        }
    }
}

#mginner {
    overflow: hidden;
    position: relative;
    padding: mixins.spacing(0, 0, 0.5, 0);
}

#mg {
    overflow: scroll;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    z-index: 100;
    user-select: none;
    padding-right: 1rem;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    @include mixins.up('ms') {
        overflow: hidden;
        overflow-y: auto;
    }

    @include mixins.up('md') {
        left: 3px;
    }
}
