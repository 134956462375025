@use '../../../mixins';

.biomet {
    order: 7;

    .wrap {
        height: 100%;

        .overview {
            text-align: center;
            width: 100%;

            img {
                width: 175px;
                height: 175px;
                @include mixins.up('md') {
                    width: 225px;
                    height: auto;
                    padding: mixins.spacing(2, 0);
                }
            }
        }

        .details {
            width: 100%;
            padding: mixins.spacing(6, 2, 0, 2);

            div {
                display: flex;
                align-items: center;
                justify-content: space-between;

                span {
                    font-size: 0.85rem;
                }

                img {
                    width: 120px;
                }
            }
        }

        .detail {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                font-size: 0.85rem;
            }

            img {
                width: 120px;
            }
        }
    }
}
