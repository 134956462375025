@use '../../mixins';

.ad-mobile-wrap {
    width: 100%;
    padding: 2px 0;

    div {
        margin: 0 auto;
        text-align: center;
    }

    &.google {
        min-height: 326px;
    }

    @include mixins.up('sm') {
        display: none;
    }
}

.ad-box-wrap-google {
    margin-bottom: mixins.spacing(1.5);
}

.side-slot-2 {
    position: sticky;
    top: 105px;
}

.side-slot-1 {
    margin-bottom: 10px;
}

.ad-top-wrap {
    width: 100%;
    margin-bottom: mixins.spacing(0.5);

    .mobile {
        margin: 0 auto;
        text-align: center;
    }
}

.ad-wide-wrap {
    width: 100%;
    min-height: 326px;
    padding: 2px 0;

    @include mixins.up('sm') {
        display: block;
        min-height: 290px;
        margin: mixins.spacing(0, 0, 1, 0);
    }
}

.ad-footer-wrap {
    position: fixed;
    bottom: 56px;
    left: 0;
    right: 0;
    background-color: var(--item-secondary-bg-color);
    border-top: 1px solid #e2e2e2;
    z-index: 50000;
    opacity: 0;
    max-height: 105px;

    @include mixins.up('md') {
        bottom: 0;
        max-height: none;
        max-width: 100%;
        overflow: hidden;
    }

    div {
        margin: 0 auto;
        text-align: center;
    }

    #footerAdClose {
        position: absolute;
        right: 8px;
        top: 2px;
        z-index: 1000;
        padding: mixins.spacing(0.5, 0);
    }
}

.ad {
    &.top {
        order: 2;

        @include mixins.up('sm') {
            display: none;
        }
    }

    &.middle {
        order: 5;
    }

    &.bottom {
        order: 9;
    }

    &.wide {
        width: 100%;
        display: block;
        margin: 0 auto;
        text-align: center;
        height: 320px;
        @include mixins.up('sm') {
            height: 290px;
        }
    }

    &.mobile {
        display: block;
        margin: 0 auto;
        margin-top: mixins.spacing(0.5);
        margin-bottom: mixins.spacing(0.5);
        text-align: center;
        height: 300px;

        @include mixins.up('sm') {
            display: none;
        }
    }

    &.box {
        display: none;
        width: 100%;
        min-height: 320px;
        margin: 0 auto;
        margin-top: mixins.spacing(0.5);
        margin-bottom: mixins.spacing(0.5);
        text-align: center;

        @include mixins.up('sm') {
            display: block;
        }
    }

    &.desktop {
        display: none;
        @include mixins.up('sm') {
            display: block;
        }
    }
}
