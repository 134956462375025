@use '../mixins';
@use 'colors';

/*
    bg_ colour are also used in layout.eta <meta name="theme-color" />
*/

$bg_sun: #e08a68;
$fg_sun: #fff;
$bg_night: #404f60;
$fg_night: #fff;
$bg_cloudLight: #d3d6da;
$fg_cloudLight: #323232;
$bg_cloudHeavy: #8894a3;
$fg_cloudHeavy: #fff;
$bg_rainLight: #667d9d;
$fg_rainLight: #fff;
$bg_rainHeavy: #507389;
$fg_rainHeavy: #fff;
$bg_rainThunder: #595675;
$fg_rainThunder: #fff;

body.sun {
    .today h1,
    .ad-wide-wrap.top,
    nav.appbar,
    #share ul,
    .gridContainer .item h2:not(.simple) {
        background-color: $bg_sun;
        color: $fg_sun;
    }

    #pageContent {
        background: top right url('/public/images/bg/nowcast/sun.svg') no-repeat;
    }

    @include mixins.up('sm') {
        .nowcast .wrap {
            background: #fff top right url(/public/images/bg/nowcast/sun.svg) no-repeat;
        }
    }

    @include mixins.up('sm') {
        .todaycast .wrap {
            background: #fff top right url(/public/images/bg/nowcast/sun.svg) no-repeat;
        }
    }
}

body.night {
    .today h1,
    .ad-wide-wrap.top,
    nav.appbar,
    #share ul,
    .gridContainer .item h2:not(.simple) {
        background-color: $bg_night;
        color: $fg_night;
    }

    #pageContent {
        background: top right url('/public/images/bg/nowcast/night.svg') no-repeat;
    }

    @include mixins.up('sm') {
        .nowcast .wrap {
            background: #fff top right url(/public/images/bg/nowcast/night.svg) no-repeat;
        }
    }

    @include mixins.up('sm') {
        .todaycast .wrap {
            background: #fff 100% -20% url(/public/images/bg/nowcast/night.svg) no-repeat;
        }
    }

    @include mixins.up('md') {
        .todaycast .wrap {
            background: #fff top right url(/public/images/bg/nowcast/night.svg) no-repeat;
        }
    }
}

body.cloudLight {
    .today h1,
    .ad-wide-wrap.top,
    nav.appbar,
    #share ul,
    .gridContainer .item h2:not(.simple) {
        background-color: $bg_cloudLight;
        color: $fg_cloudLight;
    }

    #pageContent {
        background: top right url('/public/images/bg/nowcast/cloud-light.svg') no-repeat;
    }

    @include mixins.up('sm') {
        .nowcast .wrap {
            background: #fff top right url(/public/images/bg/nowcast/cloud-light.svg) no-repeat;
        }
    }

    @include mixins.up('sm') {
        .todaycast .wrap {
            background: #fff 100% -20% url(/public/images/bg/nowcast/cloud-light.svg) no-repeat;
        }
    }

    @include mixins.up('md') {
        .todaycast .wrap {
            background: #fff top right url(/public/images/bg/nowcast/cloud-light.svg) no-repeat;
        }
    }
}

body.cloudHeavy {
    .today h1,
    .ad-wide-wrap.top,
    nav.appbar,
    #share ul,
    .gridContainer .item h2:not(.simple) {
        background-color: $bg_cloudHeavy;
        color: $fg_cloudHeavy;
    }

    #pageContent {
        background: top right url('/public/images/bg/nowcast/cloud-heavy.svg') no-repeat;
    }

    @include mixins.up('sm') {
        .nowcast .wrap {
            background: #fff top right url(/public/images/bg/nowcast/cloud-heavy.svg) no-repeat;
        }
    }

    @include mixins.up('sm') {
        .todaycast .wrap {
            background: #fff 100% -20% url(/public/images/bg/nowcast/cloud-heavy.svg) no-repeat;
        }
    }

    @include mixins.up('md') {
        .todaycast .wrap {
            background: #fff top right url(/public/images/bg/nowcast/cloud-heavy.svg) no-repeat;
        }
    }
}

body.rainLight {
    .today h1,
    .ad-wide-wrap.top,
    nav.appbar,
    #share ul,
    .gridContainer .item h2:not(.simple) {
        background-color: $bg_rainLight;
        color: $fg_rainLight;
    }

    #pageContent {
        background: top right url('/public/images/bg/nowcast/rain-light.svg') no-repeat;
    }

    @include mixins.up('sm') {
        .nowcast .wrap {
            background: #fff top right url(/public/images/bg/nowcast/rain-light.svg) no-repeat;
        }
    }

    @include mixins.up('sm') {
        .todaycast .wrap {
            background: #fff 100% -20% url(/public/images/bg/nowcast/rain-light.svg) no-repeat;
        }
    }

    @include mixins.up('md') {
        .todaycast .wrap {
            background: #fff top right url(/public/images/bg/nowcast/rain-light.svg) no-repeat;
        }
    }
}

body.rainHeavy {
    .today h1,
    .ad-wide-wrap.top,
    nav.appbar,
    #share ul,
    .gridContainer .item h2:not(.simple) {
        background-color: $bg_rainHeavy;
        color: $fg_rainHeavy;
    }

    #pageContent {
        background: top right url('/public/images/bg/nowcast/rain-heavy.svg') no-repeat;
    }

    @include mixins.up('sm') {
        .nowcast .wrap {
            background: #fff top right url(/public/images/bg/nowcast/rain-heavy.svg) no-repeat;
        }
    }

    @include mixins.up('sm') {
        .todaycast .wrap {
            background: #fff 100% -20% url(/public/images/bg/nowcast/rain-heavy.svg) no-repeat;
        }
    }

    @include mixins.up('md') {
        .todaycast .wrap {
            background: #fff top right url(/public/images/bg/nowcast/rain-heavy.svg) no-repeat;
        }
    }
}

body.rainThunder {
    .today h1,
    .ad-wide-wrap.top,
    nav.appbar,
    #share ul,
    .gridContainer .item h2:not(.simple) {
        background-color: $bg_rainThunder;
        color: $fg_rainThunder;
    }

    #pageContent {
        background: top right url('/public/images/bg/nowcast/rain-thunder.svg') no-repeat;
    }

    @include mixins.up('sm') {
        .nowcast .wrap {
            background: #fff top right url(/public/images/bg/nowcast/rain-thunder.svg) no-repeat;
        }
    }

    @include mixins.up('sm') {
        .todaycast .wrap {
            background: #fff 100% -20% url(/public/images/bg/nowcast/rain-thunder.svg) no-repeat;
        }
    }

    @include mixins.up('md') {
        .todaycast .wrap {
            background: #fff top right url(/public/images/bg/nowcast/rain-thunder.svg) no-repeat;
        }
    }
}

body.sun,
body.night,
body.cloudLight,
body.cloudHeavy,
body.rainLight,
body.rainHeavy,
body.rainThunder {
    @include mixins.up('sm') {
        #pageContent,
        .ad-wide-wrap.top {
            background: none;
        }
    }

    @include mixins.up('md') {
        section.quickLinks {
            background-color: #fff;
        }
    }
}

body.sun,
body.night,
body.cloudHeavy,
body.rainLight,
body.rainHeavy,
body.rainThunder {
    section.toolbar button#drawerToggle,
    section.toolbar #share button,
    section.toolbar #share a {
        color: white;
    }
}

nav.drawer,
section.toolbar {
    img.logo {
        &.white {
            display: none;
        }
    }
}

section.recommend {
    img.recommendationImage {
        &.white {
            display: none;
        }
    }
}

body.theme-dark {
    &.sun,
    &.night,
    &.cloudLight,
    &.cloudHeavy,
    &.rainLight,
    &.rainHeavy,
    &.rainThunder {
        #pageContent {
            background: none;
        }

        .today h1,
        .ad-wide-wrap.top,
        .gridContainer .item h2:not(.simple) {
            background-color: var(--heading-bg-color);
            color: var(--heading-text-color);
        }

        nav.appbar,
        #share ul {
            background-color: var(--appbar-bg-color);
            color: var(--heading-text-color);
        }

        .nowcast .wrap {
            background-color: var(--bg-color);
        }

        @include mixins.up('sm') {
            .nowcast .wrap {
                background: none;
                background-color: var(--item-bg-color);
            }
        }

        @include mixins.up('sm') {
            .todaycast .wrap {
                background: none;
                background-color: var(--item-bg-color);
            }
        }

        @include mixins.up('md') {
            section.quickLinks {
                background-color: var(--quicklinks-bg-color);
            }
        }
    }

    nav.drawer,
    section.toolbar {
        img.logo {
            &.white {
                display: inline;
            }
            &.black {
                display: none;
            }
        }
    }

    section.recommend {
        img.recommendationImage {
            &.white {
                display: inline;
            }
            &.black {
                display: none;
            }
        }
    }
}
