@use '../../../mixins';

.mapteaser {
    order: 4;

    @include mixins.up('sm') {
        order: 7;
    }

    @include mixins.up('md') {
        order: 4;
    }
}
