@use '../../mixins';
@use '../colors';

$rain: #0073bc;
$snow: #7373da;

.days {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: colors.$itemSecondaryBgColor;
    background-color: var(--item-secondary-bg-color);
    border-bottom-style: solid;
    border-bottom-color: colors.$itemBorderColor;
    border-bottom-color: var(--item-border-color);
    border-bottom-width: 1px;

    &.bottom {
        border-bottom-style: none;
    }

    .weekday {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        height: 40px;
        min-width: 24px;
        font-size: 0.8rem;
        @include mixins.up('sm') {
            font-size: 0.9rem;
            text-transform: uppercase;
            margin: 4px;
        }

        &.selected {
            background-color: $rain;
            color: white;
            font-weight: 700;
            border-radius: 2px;
        }
    }
    a {
        display: block;
        text-decoration: none;
        width: 100%;
        color: inherit;
    }
}

.summary {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include mixins.up('sm') {
        align-items: flex-start;
        padding: mixins.spacing(1, 1, 2, 1);
    }

    &.notext {
        .dateTime {
            @include mixins.up('md') {
                flex: 1 0 50%;
            }
        }
    }

    .dateTime {
        flex: 1 0 100%;
        display: flex;
        align-items: baseline;
        padding: mixins.spacing(1, 1, 0, 2);

        @include mixins.up('sm') {
            order: 1;
            flex: 1;
            flex-direction: column;
            padding: mixins.spacing(1, 1, 0, 1);
        }

        @include mixins.up('md') {
            flex: 1 0 80%;
        }

        .dayName {
            flex: 0 0 auto;
            background-color: transparent;
            font-weight: 700;
            font-size: 1.4rem;
            text-transform: uppercase;

            @include mixins.up('sm') {
                flex: 1;
            }
        }

        .date {
            flex: 1;
            font-weight: 500;
            font-size: 0.9rem;
            color: colors.$lightTextColor;
            color: var(--light-text-color);
            padding: mixins.spacing(0, 0, 0, 1.5);

            @include mixins.up('sm') {
                padding: 0;
            }
        }
    }

    .daySummary {
        flex: 1 0 100%;
        padding: mixins.spacing(1, 1, 0, 1);

        @include mixins.up('sm') {
            order: 4;
            // flex: 1 0 100%;
        }

        @include mixins.up('md') {
            order: 3;
            flex: 1 0 75%;
        }

        .dailyRain {
            padding: mixins.spacing(1, 0, 0, 0);

            img {
                display: inline-block;
                vertical-align: middle;
                margin: -1px 0px 0 0;
            }
        }
    }

    .dayLength {
        flex: 1;
        min-height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        background-image: url(//cache.foreca.net/static/img/daylen.svg);
        background-repeat: no-repeat;
        background-position: 50% 30%;
        background-size: 130px 45px;

        @include mixins.up('sm') {
            order: 2;
        }

        @include mixins.up('md') {
            flex: 1 0 20%;
        }

        .row {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .moonPhase {
        flex: 1;
        display: flex;
        align-items: center;
        padding: mixins.spacing(2, 0, 0, 0);

        @include mixins.up('sm') {
            order: 3;
        }

        @include mixins.up('md') {
            order: 4;
            flex: 1 0 25%;
            justify-content: flex-end;
            padding: mixins.spacing(2, 3, 0, 0);
        }

        .icon {
            padding: mixins.spacing(0, 1, 0, 0);

            @include mixins.up('sm') {
                padding: mixins.spacing(0, 1, 0, 0);
            }
        }

        .descr {
            .phase {
                text-align: center;
            }

            .moonTime {
                justify-content: center;

                @include mixins.up('sm') {
                    display: flex;
                    padding: mixins.spacing(0.5, 0, 0, 0);
                }

                .moonrise,
                .moonset {
                    white-space: nowrap;
                }
            }
        }
    }

    .dailyRain {
        flex: 1 0 100%;
        order: 5;
        padding: mixins.spacing(1, 0, 0, 2);

        @include mixins.up('sm') {
            padding: mixins.spacing(1, 0, 0, 1);
        }

        img {
            display: inline-block;
            vertical-align: middle;
            margin: -1px 0.15rem 0 0;
        }
    }
}

#hourly-component {
    position: relative;
}

.legendContainer {
    position: sticky;
    top: 56px;

    @include mixins.up('sm') {
        top: 64px;
    }

    @include mixins.up('md') {
        top: 93px;
    }

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    background-color: colors.$itemSecondaryBgColor;
    background-color: var(--item-secondary-bg-color);
    border-bottom-style: solid;
    border-bottom-color: colors.$itemBorderColor;
    border-bottom-color: var(--item-border-color);
    border-bottom-width: 1px;
    color: colors.$lightTextColor;
    color: var(--light-text-color);
    padding-right: mixins.spacing(1);

    .rest {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
    }

    .editableField {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: center;

        &.lastItem {
            align-items: flex-end;
        }

        .icon {
            border: 1px solid var(--light-border-color);
            border-radius: 4px;
            margin: 10px 0;
            height: 30px;

            &:hover {
                color: var(--text-color);
                border: 1px solid var(--light-text-color);
            }
        }

        ul {
            top: 42px;
            position: absolute;
            background-color: var(--item-bg-color);
            border: 1px solid #000;
            border-radius: 4px;
            padding: mixins.spacing(0.5, 1);

            li {
                cursor: pointer;
                color: #aaa;

                &.current {
                    color: var(--text-color);
                }

                &.inactive {
                    color: #ccc;
                }

                &:hover {
                    color: var(--text-color);
                }
            }
        }
    }

    .icon {
        position: relative;
        display: flex;
        align-items: center;

        height: 50px;
        min-width: 48px;
        justify-content: center;

        @include mixins.up('sm') {
            min-width: 54px;
        }

        &.time {
            width: 40px;
            min-width: 40px;
            @include mixins.up('sm') {
                width: 80px;
                padding-right: 20px;
            }
        }

        &.symb {
            @include mixins.up('md') {
                // 14px padding for wider symbol
                padding-left: 57px;
                padding-right: 57px;
                height: 50px;
            }
        }

        &.precipitation {
            @include mixins.up('sm') {
                min-width: 60px;
            }
        }

        &.wind {
            min-width: 60px;
        }
    }

    // Tooltips for the legend icons:

    .tooltip {
        @include mixins.up('sm') {
            &::after {
                content: attr(data-tooltip);
                position: absolute;
                bottom: 100%;
                left: 50%;
                transform: translateX(-50%);
                background-color: #333;
                color: #fff;
                padding: 5px;
                border-radius: 3px;
                white-space: nowrap;
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.2s;
            }

            &:hover::after {
                opacity: 1;
                visibility: visible;
            }

            @media (hover: none) and (pointer: coarse) {
                &.active::after {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.hourContainer {
    background-color: colors.$listBgColor;
    background-color: var(--list-bg-color);

    .hour {
        border-bottom-style: solid;
        border-bottom-color: colors.$itemBorderColor;
        border-bottom-color: var(--item-border-color);
        border-bottom-width: 1px;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: mixins.spacing(0.5, 1, 0.25, 0);

        &.last {
            border-bottom-style: none;
        }

        .row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
        }

        .dataRow {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            align-items: center;

            flex-grow: 1;

            span {
                text-align: center;
            }
        }

        div.time {
            width: 40px;
            min-width: 40px;
            text-align: center;

            @include mixins.up('sm') {
                width: 80px;
                padding-right: 20px;
            }
        }

        .symb,
        .t,
        .feelsLike,
        .humidity,
        .precipContainer,
        .dewpoint,
        .none {
            min-width: 48px;
            text-align: center;

            @include mixins.up('sm') {
                min-width: 54px;
            }
        }

        .symb {
            img {
                width: 28px;
                height: 28px;
                @include mixins.up('sm') {
                    width: 34px;
                    height: 34px;
                }
            }

            @include mixins.up('md') {
                padding-left: 50px;
                padding-right: 50px;
            }
        }

        .t {
            font-size: 1.1rem;
        }

        .aqi {
            &.aqi_0 {
                border-color: rgb(0, 228, 0);
            }
            &.aqi_1 {
                border-color: rgb(250, 250, 0);
            }
            &.aqi_2 {
                border-color: rgb(255, 126, 0);
            }
            &.aqi_3 {
                border-color: rgb(255, 0, 0);
            }
            &.aqi_4 {
                border-color: rgb(143, 63, 151);
            }
            &.aqi_5 {
                border-color: rgb(126, 0, 35);
            }
        }

        .uvi {
            &.uvi_0 {
                border-color: rgb(192, 192, 192);
            }
            &.uvi_1 {
                border-color: rgb(78, 180, 0);
            }
            &.uvi_2 {
                border-color: rgb(160, 206, 0);
            }
            &.uvi_3 {
                border-color: rgb(247, 228, 0);
            }
            &.uvi_4 {
                border-color: rgb(248, 182, 0);
            }
            &.uvi_5 {
                border-color: rgb(248, 135, 0);
            }
            &.uvi_6 {
                border-color: rgb(248, 89, 0);
            }
            &.uvi_7 {
                border-color: rgb(232, 44, 14);
            }
            &.uvi_8 {
                border-color: rgb(216, 0, 29);
            }
            &.uvi_9 {
                border-color: rgb(255, 0, 153);
            }
            &.uvi_10 {
                border-color: rgb(181, 76, 255);
            }
            &.uvi_11 {
                border-color: rgb(153, 140, 255);
            }
        }

        .aqi,
        .uvi {
            border: 2px solid colors.$lightTextColor;
            border: 2px solid var(--light-text-color);
            border-radius: 4px;
            text-align: center;

            margin: 0 7px;
            min-width: 40px;
            @include mixins.up('sm') {
                margin: 0 2px 0 6px;
                min-width: 45px;
            }
        }

        .precipContainer {
            @include mixins.up('sm') {
                min-width: 60px;
            }
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .precipitation {
            color: $rain;

            &.snow {
                color: $snow;
            }
        }

        .noPrecipitation {
            color: colors.$lightTextColor;
            color: var(--light-text-color);
        }

        .unit {
            font-size: 0.7rem;
        }

        div.wind {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            min-width: 60px;

            @include mixins.up('sm') {
                min-width: 65px;
            }

            .windSpeed {
                min-width: 25px;
                @include mixins.up('sm') {
                    min-width: 30px;
                }
            }

            img {
                height: 25px;
                width: 25px;
            }
        }

        .textRow {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            font-weight: 400;
            font-size: 0.8rem;

            .symbolText {
                text-align: start;
                padding-left: 12px;
                padding-right: 8px;
                @include mixins.up('md') {
                    padding-left: 50px;
                }
                color: colors.$lightTextColor;
                color: var(--light-text-color);
            }

            .windText {
                white-space: nowrap;
                text-align: end;
                flex-grow: 1;
                color: colors.$lightTextColor;
                color: var(--light-text-color);
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }

        .precipChance {
            align-self: flex-end;
            color: colors.$lightTextColor;
            color: var(--light-text-color);
            font-weight: 400;
            font-size: 0.8rem;
        }
    }

    > .sunny {
        background: linear-gradient(90deg, rgba(254, 211, 127, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
    }
    > .cloudy {
        background: linear-gradient(90deg, rgba(232, 232, 232, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
    }
    > .drizzle {
        background: linear-gradient(90deg, rgba(90, 216, 228, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
    }
    > .rain {
        background: linear-gradient(90deg, rgba(159, 202, 230, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
    }
    > .heavyrain {
        background: linear-gradient(90deg, rgba(182, 206, 215, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
    }
    > .storm {
        background: linear-gradient(90deg, rgba(192, 157, 233, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
    }
    > .nightclear {
        background: linear-gradient(90deg, rgba(131, 165, 196, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
    }
    > .nightcloudy {
        background: linear-gradient(90deg, rgba(184, 194, 199, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
    }
    > .nightdrizzle {
        background: linear-gradient(90deg, rgba(79, 198, 195, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
    }
    > .nightrain {
        background: linear-gradient(90deg, rgba(118, 177, 206, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
    }
    > .nightheavyrain {
        background: linear-gradient(90deg, rgba(151, 187, 193, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
    }
    > .nightstorm {
        background: linear-gradient(90deg, rgba(157, 154, 204, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
    }
}
