@use '../mixins';
@use 'colors';

html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

:root {
    --swiper-scrollbar-border-radius: 2px;
    --swiper-scrollbar-bg-color: #f6f6f6;
    --swiper-scrollbar-drag-bg-color: #e8e8e8;
    --swiper-scrollbar-size: 4px;

    @include mixins.up('ms') {
        --swiper-scrollbar-top: 424px;
    }

    .swiper-horizontal > .swiper-pagination-progressbar,
    .swiper-pagination-progressbar.swiper-pagination-horizontal {
        top: 0px;
        bottom: auto;
    }

    --bg-color: #{colors.$bgColor};
    --light-bg-color: #{colors.$lightBgColor};

    --heading-bg-color: #{colors.$headingBgColor};
    --item-bg-color: #{colors.$itemBgColor};
    --list-bg-color: #{colors.$listBgColor};
    --item-secondary-bg-color: #{colors.$itemSecondaryBgColor};
    --item-border-color: #{colors.$itemBorderColor};
    --hover-color: #{colors.$hoverColor};
    --map-bg-color: #{colors.$mapBgColor};

    --link-bg-color: #{colors.$linkBgColor};
    --link-text-color: #{colors.$linkTextColor};
    --link-teaser-text-color: #{colors.$linkTeaserTextColor};

    --light-border-color: #ccc;

    --text-color: #{colors.$textColor};
    --light-text-color: #{colors.$lightTextColor};
    --reverse-text-color: #{colors.$reverseTextColor};
    --heading-text-color: #{colors.$headingTextColor};

    --appbar-bg-color: #{colors.$appbarBgColor};
    --appbar-button-color: #{colors.$appbarButtonColor};

    --quicklinks-bg-color: #{colors.$quicklinksBgColor};
    --bottom-navi-bg-color: #{colors.$bottomNaviBgColor};
    --footer-bg-color: #{colors.$footerBgColor};
    --mg-scale-bg-color: #{colors.$mgScaleBgColor};

    --button-active-color: #{colors.$buttonActiveColor};
    --button-outline-color: #{colors.$buttonOutlineColor};

    --search-results-bg-color: #{colors.$searchResultsBgColor};

    --checked-bg-color: #{colors.$checkedBgColor};

    .dark-theme {
        display: none;
    }

    .light-theme {
        display: block;
    }

    body.theme-dark {
        --bg-color: #000;
        --light-bg-color: transparent;

        --heading-bg-color: #454545;
        --item-bg-color: #1c1c1e;
        --list-bg-color: #1c1c1e;
        --item-secondary-bg-color: #545454;
        --item-border-color: rgb(66, 66, 66);
        --hover-color: rgb(80, 80, 80);
        --map-bg-color: rgb(76, 105, 76);

        --light-border-color: #666;

        --link-bg-color: #252526;
        --link-text-color: #fff;
        // --link-teaser-text-color: #2fb0ff;
        --link-teaser-text-color: #fff;

        --text-color: #fff;
        --reverse-text-color: #000;
        --heading-text-color: #fff;

        --appbar-bg-color: #000;
        --appbar-button-color: #fff;

        --quicklinks-bg-color: #545454;
        --bottom-navi-bg-color: #333;
        --footer-bg-color: #333;
        --mg-scale-bg-color: #1c1c1eaa;

        --search-results-bg-color: #1c1c1efa;

        --checked-bg-color: #2fb0ff;

        --button-active-color: #fff;
        --button-outline-color: rgba(255, 255, 255, 0.3);

        .dark-theme {
            display: block;
        }

        .light-theme {
            display: none;
        }
    }

    body.theme-halloween {
        --heading-text-color: rgb(255, 154, 1);
        --link-text-color: rgb(255, 154, 1);
    }
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
ul,
li {
    margin: 0;
    padding: 0;
}

em {
    font-weight: 500;
    font-style: normal;
}

h1,
h2.simple {
    font-size: 2em;
    font-weight: 300;
    padding: mixins.spacing(1);
}

ul {
    list-style-type: none;
}

hr {
    border: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.12);
}

body {
    margin: 0;
    font-size: 0.875rem;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-weight: 400;
    line-height: 1.45;

    background-color: colors.$bgColor;
    background-color: var(--bg-color);
}

.right {
    text-align: right;
}

.center {
    text-align: center;
}

.hidden {
    display: none;
}

.warm {
    color: colors.$tempWarm;
}

.cold {
    color: colors.$tempCold;
}

nav.appbar {
    width: 100%;
    display: flex;
    left: auto;
    right: 0;
    top: 0;
    position: fixed;
    flex-shrink: 0;
    flex-direction: column;
    z-index: 3000;
    background-color: colors.$appbarBgColor;
    background-color: var(--appbar-bg-color);
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgba(0, 0, 0, 0.12);

    #feedback {
        display: none;
        @include mixins.up('sm') {
            display: flex;
        }
        margin-top: 0.6rem;
        padding: mixins.spacing(1.2, 3, 3, 3);
        background-image: url(/public/images/feedback_outlined.svg);
        background-repeat: no-repeat;
        background-position: top;
        background-size: 60px;
        height: 45px;
        flex: 0;
        justify-content: center;
        align-items: center;
        p {
            vertical-align: middle;
        }
        a {
            text-decoration: none;
            color: colors.$linkTextColor;
            font-weight: 600;
            font-size: 0.6rem;
            line-height: 100%;
        }
    }
}

body.theme-dark {
    nav.drawer {
        .container {
            .settings {
                .theme {
                    .button {
                        &.dark {
                            display: none;
                        }

                        &.light {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

nav.drawer {
    inset: 0;
    z-index: 3000;
    position: fixed;

    .backdrop {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        display: flex;
        position: fixed;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-tap-highlight-color: transparent;
        opacity: 1;
    }

    .container {
        top: 0;
        left: 0;
        right: auto;
        width: 100%;
        height: 100%;
        display: flex;
        outline: 0;
        z-index: 2900;
        position: fixed;
        overflow: auto;
        flex-direction: column;
        color: colors.$textColor;
        color: var(--text-color);
        background-color: colors.$itemBgColor;
        background-color: var(--item-bg-color);
        -webkit-overflow-scrolling: touch;
        padding: mixins.spacing(1.5, 2, 0, 2);

        @include mixins.up('sm') {
            width: 300px;
            border-top-style: solid;
            border-top-width: mixins.spacing(0.5);
            border-top-color: #2fb0ff;
            box-shadow: 0px 16px 24px 2px rgba(0, 0, 0, 0.14);
        }

        h5 {
            font-weight: 600;
            font-size: 0.9rem;
        }

        .menuHeader {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 60px;

            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: mixins.spacing(0, 2, 1, 2);

            background-color: #fff;
            background-color: var(--item-bg-color);

            border-bottom-style: solid;
            border-bottom-width: mixins.spacing(0.5);
            border-bottom-color: #2fb0ff;

            @include mixins.up('sm') {
                border: none;
                position: static;
                background-color: transparent;
                padding: mixins.spacing(1, 0, 1, 0);
            }

            &:first-child {
                padding-top: 0;
            }

            .logo {
                flex: 1;
            }

            .languages {
                max-width: 140px;

                select {
                    border: 0;
                    font-size: 1rem;
                    cursor: pointer;
                    color: var(--text-color);
                    margin-top: 8px;
                    background-color: var(--item-bg-color);
                    padding: 0px 0px 4px 4px;
                    border-bottom: 1px solid #888;
                    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                }
            }

            #drawerClose {
                padding: mixins.spacing(0.5, 0.5, 0, 0);

                @include mixins.up('sm') {
                    display: none;
                }
            }
        }

        .location {
            padding: mixins.spacing(7, 0, 1, 0);
            display: flex;
            align-items: center;

            @include mixins.up('sm') {
                padding-top: 0;
            }

            .favorite {
                flex-grow: 1;
                padding: 0;
            }
        }

        .settings {
            .mainsettings {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                padding: mixins.spacing(1, 0, 0, 0);

                .units_wrap {
                    max-width: 40%;
                }

                .timeformat_wrap {
                    max-width: 45%;
                }

                .theme_wrap {
                    max-width: 15%;
                }
            }

            .moresettings_wrap {
                .hide {
                    display: none;
                }

                .toggle {
                    display: flex;
                    align-items: center;
                    margin: mixins.spacing(1.5, 0, 0, 0);

                    .spacer {
                        flex: 1;
                        flex-grow: 1;
                        height: 1px;
                        margin: mixins.spacing(0, 1, 0, 0);
                        border-bottom: 1px solid #{colors.$itemBorderColor};
                        border-bottom: 1px solid var(--item-border-color);
                    }

                    p {
                        cursor: pointer;
                        user-select: none;
                    }
                }

                .moresettings {
                    max-height: 0;
                    transition: max-height 0.5s ease;
                    overflow: hidden;

                    &.open {
                        height: auto;
                        max-height: 20rem;
                    }
                }

                .radio {
                    margin: mixins.spacing(1, 1, 2, 0);
                }
            }

            .theme {
                text-align: center;

                .button {
                    cursor: pointer;
                    margin: 0 auto;
                    margin-top: mixins.spacing(1);

                    &.light {
                        display: none;
                    }
                }
            }

            .radio {
                display: flex;
                margin: mixins.spacing(1, 2, 0, 0);

                input {
                    opacity: 0;
                    width: 0;
                    height: 0;
                    position: absolute;

                    &:checked + .title {
                        font-weight: 600;
                        color: white;
                        background-color: colors.$checkedBgColor;
                        background-color: var(--checked-bg-color);
                    }
                }

                .title {
                    font-weight: 500;
                    cursor: pointer;
                    color: colors.$lightTextColor;
                    color: var(--light-text-color);
                    background-color: colors.$itemSecondaryBgColor;
                    background-color: var(--item-secondary-bg-color);
                    padding: mixins.spacing(0.5, 1.5);
                    border-right: 1px solid #d8d8d8;
                }

                &:first-child .title {
                    border-radius: mixins.spacing(0.3, 0, 0, 0.3);
                }

                &:last-child .title {
                    border-radius: mixins.spacing(0, 0.3, 0.3, 0);
                    border-right: none;
                }
            }
        }

        .links {
            margin-top: mixins.spacing(1);

            .icon {
                margin-right: 10px;
                color: colors.$lightTextColor;
                color: var(--light-text-color);
                width: 20px;
                height: 20px;
            }

            li {
                &.header {
                    font-weight: 600;
                }

                a {
                    display: flex;
                    align-items: center;
                    text-decoration: none;
                    padding: mixins.spacing(0.75, 0, 0.75, 1);
                    color: inherit;
                }

                span {
                    &.new {
                        font-weight: bold;
                        margin-left: 10px;
                        color: cornflowerblue;
                    }
                }

                .icon {
                    &.new {
                        margin-left: 10px;
                        margin-right: 0;
                    }
                } 
            }
        }
    }
}

nav.buttons {
    bottom: 0;
    position: sticky;
    height: 56px;
    z-index: 2000;

    @include mixins.up('md') {
        display: none;
    }

    border-top: 1px solid colors.$itemBorderColor;
    border-top: 1px solid var(--item-border-color);
    background-color: colors.$bottomNaviBgColor;
    background-color: var(--bottom-navi-bg-color);

    ul {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        li {
            flex: 1;

            a {
                display: inline-flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                width: 100%;
                text-decoration: none;
                text-align: center;
                color: colors.$textColor;
                color: var(--text-color);
                padding-top: spacing(0.75);
                font-size: 0.8rem;
                &.active {
                    color: #2fb0ff;
                }

                svg {
                    width: 1.15rem;
                    height: 1.15rem;
                }
            }
        }
    }
}

button {
    &.icon {
        color: inherit;
        display: inline-flex;
        align-items: center;
        padding: mixins.spacing(1.5);
        font-size: 1.5rem;
        text-align: center;
        cursor: pointer;
        user-select: none;
        background-color: transparent;
        margin: 0;
        outline: 0;
        border: 0;
    }
}

main {
    flex-grow: 1;
    max-width: 100%;
    color: colors.$textColor;
    color: var(--text-color);
    padding-top: 53px;

    @include mixins.up('sm') {
        padding-top: 100px;
    }

    @include mixins.up('md') {
        width: 1330px;
        max-width: 1330px;
        margin: 0 auto;
    }
}

#content {
    flex: 1 1;
    display: flex;
    max-width: 100%;
    flex-direction: row;
    justify-content: center;

    @include mixins.up('sm') {
        background: none;
    }
}

#container {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    position: relative;
    padding: mixins.spacing(0, 0, 1, 0);
    @include mixins.up('sm') {
        padding: mixins.spacing(0, 3);
    }

    @include mixins.up('md') {
        width: 980px;
        padding: mixins.spacing(0, 0, 1, 0.5);
    }
}

#adContainer {
    display: none;
    @include mixins.up('md') {
        display: block;
        width: 350px;
        padding: 0;
        padding-left: 10px;

        &.padTop {
            margin-top: calc(4px + 3.5rem);
        }
    }
}

#pageContent {
    padding: mixins.spacing(0, 1);

    @include mixins.up('md') {
        padding: 0;
    }
}

.gridContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    h1 {
        flex: 1;
    }

    .item {
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
        margin: mixins.spacing(0, 0, 1, 0);
        background-color: colors.$itemBgColor;
        background-color: var(--item-bg-color);

        &.legalese {
            padding: mixins.spacing(2, 4);
            line-height: 1.5rem;
            background-color: colors.$itemBgColor;
            background-color: var(--item-bg-color);
            p,
            ul {
                padding: mixins.spacing(1, 0);
            }
        }

        @include mixins.up('sm') {
            padding: mixins.spacing(0, 0.5);
            background-color: transparent;

            &.meteogram {
                padding: 0;
            }

            .wrap {
                background-color: colors.$itemBgColor;
                background-color: var(--item-bg-color);
            }
        }

        &.nowcast {
            background-color: transparent;
        }

        // &.daily {
        //     background-color: #f6f9fc;
        // }

        &.xs2 {
            @include mixins.up('xs') {
                flex-grow: 0;
                max-width: 16.666666%;
                flex-basis: 16.666666%;
            }
        }

        &.xs3 {
            @include mixins.up('xs') {
                flex-grow: 0;
                max-width: 25%;
                flex-basis: 25%;
            }
        }

        &.xs4 {
            @include mixins.up('xs') {
                flex-grow: 0;
                max-width: 33.3333333%;
                flex-basis: 33.3333333%;
            }
        }

        &.xs6 {
            @include mixins.up('xs') {
                flex-grow: 0;
                max-width: 50%;
                flex-basis: 50%;
            }
        }

        &.xs8 {
            @include mixins.up('xs') {
                flex-grow: 0;
                max-width: 66.666666%;
                flex-basis: 66.666666%;
            }
        }

        &.xs9 {
            @include mixins.up('xs') {
                flex-grow: 0;
                max-width: 75%;
                flex-basis: 75%;
            }
        }

        &.sm1 {
            @include mixins.up('sm') {
                flex-grow: 0;
                max-width: 8.333333%;
                flex-basis: 8.333333%;
            }
        }

        &.sm2 {
            @include mixins.up('sm') {
                flex-grow: 0;
                max-width: 16.666666%;
                flex-basis: 16.666666%;
            }
        }

        &.sm3 {
            @include mixins.up('sm') {
                flex-grow: 0;
                max-width: 25%;
                flex-basis: 25%;
            }
        }

        &.sm4 {
            @include mixins.up('sm') {
                flex-grow: 0;
                max-width: 33.3333333%;
                flex-basis: 33.3333333%;
            }
        }

        &.sm5 {
            @include mixins.up('sm') {
                flex-grow: 0;
                max-width: 41.6666667%;
                flex-basis: 41.6666667%;
            }
        }

        &.sm6 {
            @include mixins.up('sm') {
                flex-grow: 0;
                max-width: 50%;
                flex-basis: 50%;
            }
        }

        &.sm8 {
            @include mixins.up('sm') {
                flex-grow: 0;
                max-width: 66.666666%;
                flex-basis: 66.666666%;
            }
        }

        &.ms1 {
            @include mixins.up('ms') {
                flex-grow: 0;
                max-width: 8.333333%;
                flex-basis: 8.333333%;
            }
        }

        &.ms2 {
            @include mixins.up('ms') {
                flex-grow: 0;
                max-width: 16.666666%;
                flex-basis: 16.666666%;
            }
        }

        &.ms3 {
            @include mixins.up('ms') {
                flex-grow: 0;
                max-width: 25%;
                flex-basis: 25%;
            }
        }

        &.md4 {
            @include mixins.up('md') {
                flex-grow: 0;
                max-width: 33.333333%;
                flex-basis: 33.333333%;
            }
        }

        &.md5 {
            @include mixins.up('md') {
                flex-grow: 0;
                max-width: 41.666666%;
                flex-basis: 41.666666%;
            }
        }

        &.md6 {
            @include mixins.up('md') {
                flex-grow: 0;
                max-width: 50%;
                flex-basis: 50%;
            }
        }

        &.md7 {
            @include mixins.up('md') {
                flex-grow: 0;
                max-width: 58.333333%;
                flex-basis: 58.333333%;
            }
        }

        &.md8 {
            @include mixins.up('md') {
                flex-grow: 0;
                max-width: 66.666666%;
                flex-basis: 66.666666%;
            }
        }

        h2:not(.simple) {
            font-weight: 600;
            font-size: 1.1em;
            padding: mixins.spacing(0.5, 1);
            background-color: colors.$headingBgColor;
            background-color: var(--heading-bg-color);
            color: colors.$headingTextColor;
            color: var(--heading-text-color);
        }

        h3 {
            font-weight: 500;
            font-size: 0.85rem;
            padding: mixins.spacing(1, 0, 0, 0);
            margin: mixins.spacing(0, 1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }

        .updated {
            background-color: colors.$bgColor;
            background-color: var(--bg-color);
            color: colors.$lightTextColor;
            color: var(--light-text-color);
            padding: mixins.spacing(1, 0, 1, 0);
            text-align: right;
        }

        .explainer {
            padding: mixins.spacing(1, 2, 1, 1);
            background-color: colors.$bgColor;
            background-color: var(--bg-color);
            color: colors.$lightTextColor;
            color: var(--light-text-color);

            h3 {
                border: none;
                padding: 0;
                margin: 0;
                font-weight: 600;
            }

            p {
                margin: mixins.spacing(1, 0, 1, 0);
            }
        }
    }
}

.teaserLink {
    font-weight: 600;
    text-align: left;
    cursor: pointer;
    position: relative;
    padding: mixins.spacing(0.75, 0, 0.5, 1);
    background-color: colors.$linkBgColor;
    background-color: var(--link-bg-color);
    background-repeat: no-repeat;
    background-size: 24px 12px;
    background-position: right center;
    background-image: url(/public/icons/link-arrow-black.svg);
    border-top: 1px solid colors.$itemBorderColor;
    border-top: 1px solid var(--item-border-color);

    a {
        color: colors.$linkTeaserTextColor;
        color: var(--link-teaser-text-color);
        display: block;
        text-decoration: none;
    }
}

body.theme-dark {
    .teaserLink {
        background-image: url(/public/icons/link-arrow.svg);
    }
}

.logoLink {
    display: none;
    @include mixins.up('sm') {
        display: block;
    }
}

.logo {
    height: mixins.spacing(3);
}

.iconButton {
    color: inherit;
    display: inline-flex;
    align-items: center;
    padding: mixins.spacing(1.5);
    font-size: 1.5rem;
    text-align: center;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    margin: 0;
    outline: 0;
    border: 0;
}

section.quickLinks {
    display: none;

    @include mixins.up('md') {
        display: block;
        background-color: colors.$quicklinksBgColor;
        background-color: var(--quicklinks-bg-color);
        padding: mixins.spacing(0, 0, 0, 1);

        ul {
            display: flex;
            margin: 0 auto;
            max-width: 1304px;
            justify-content: center;
            font-weight: 400;

            li {
                flex: 1;
                text-align: center;

                &.active {
                    font-weight: 600;
                    border-bottom: 1px solid #2fb0ff;
                }

                a,
                span {
                    color: colors.$textColor;
                    color: var(--text-color);
                    display: block;
                    text-decoration: none;
                    text-transform: uppercase;
                    padding: mixins.spacing(0.5, 0);

                    &:hover {
                        cursor: pointer;
                        font-weight: 600;
                        border-bottom: 1px solid #2fb0ff;
                    }
                }
            }
        }
    }
}

section.subCategories {
    color: colors.$textColor;
    color: var(--text-color);
    background-color: colors.$itemBgColor;
    background-color: var(--item-bg-color);

    .wrap {
        display: flex;
        margin: 0 auto;
        padding: 0.5rem 5% 1rem 5%;
        max-width: 1304px;
        justify-content: space-around;
        background-color: colors.$itemBgColor;
        background-color: var(--item-bg-color);

        .subCategory {
            flex: 1;

            h4 {
                margin: mixins.spacing(1, 0);
            }

            ul {
                li {
                    padding: mixins.spacing(0.25, 0, 1, 0);
                    margin-left: mixins.spacing(-4);

                    .icon {
                        margin-right: mixins.spacing(1);
                        color: colors.$lightTextColor;
                        color: var(--light-text-color);
                        width: 20px;
                        height: 20px;
                        vertical-align: top;
                    }

                    a {
                        color: inherit;
                        display: inline-block;
                        text-decoration: none;

                        &:hover {
                            cursor: pointer;
                            font-weight: 600;
                        }
                    }

                    span {
                        &.new {
                            font-weight: bold;
                            color: cornflowerblue;
                        }
                    }

                    .icon {
                        &.new {
                            margin-right: 0;
                        }
                    } 
                }
            }
        }
    }
}

section.toolbar {
    display: flex;
    position: relative;
    align-items: center;
    padding: mixins.absoluteSpacing(0, 10px);
    min-height: mixins.spacing(7);

    @include mixins.up('sm') {
        padding: mixins.spacing(0, 6);
        min-height: mixins.spacing(8);
    }

    @include mixins.up('md') {
        width: 100%;
        max-width: 1280px;
        padding: mixins.spacing(0, 1);
        margin: 0 auto;
    }

    #search {
        color: colors.$textColor;
        color: var(--text-color);
        width: 100%;
        position: relative;
        margin-left: mixins.spacing(1);
        background-color: colors.$bgColor;
        background-color: var(--bg-color);

        .action {
            width: 3rem;
            height: 100%;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .searchIcon {
            top: 2px;
            left: 0;
        }

        #searchLocate {
            right: 0;
        }
    }
}

nav.drawer,
.ad-footer-wrap,
section.toolbar {
    button {
        color: colors.$appbarButtonColor;
        color: var(--appbar-button-color);
        display: inline-flex;
        align-items: center;
        padding: mixins.spacing(1.5, 1);
        font-size: 1.5rem;
        text-align: center;
        cursor: pointer;
        user-select: none;
        background-color: transparent;
        outline: 0;
        border: 0;
    }
}

footer {
    padding: mixins.spacing(4, 2);
    overflow: hidden;
    background-color: colors.$footerBgColor;
    background-color: var(--footer-bg-color);
    border-top-color: rgba(0, 0, 0, 0.12);
    border-top-width: 1px;
    border-top-style: solid;
    @include mixins.up('sm') {
        padding: mixins.spacing(9, 0, 10, 0);
    }

    .grid {
        position: relative;
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        margin: 0 auto;
        max-width: 1000px;
        padding: mixins.spacing(0, 4);

        .item {
            flex: 1 1 50%;
            margin-bottom: mixins.spacing(2);

            @include mixins.up('sm') {
                flex: 1;
            }

            &.copy {
                flex: 1 1 100%;

                a {
                    color: #2fb0ff;
                }
            }

            a {
                text-decoration: none;
            }

            h3 {
                color: #2fb0ff;
                margin: 0;
                text-transform: uppercase;
                font-size: 1rem;
                font-weight: 600;
            }

            ul {
                line-height: 2rem;

                &.icons {
                    margin-top: mixins.spacing(1);
                }

                li {
                    a {
                        color: colors.$textColor;
                        color: var(--text-color);
                        font-size: 0.875rem;
                        font-weight: 500;
                        text-decoration: none;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}
