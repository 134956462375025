@use '../../mixins';
@use '../colors';

$rain: rgb(47, 176, 255);

.hourList {
    .legendContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        background-color: colors.$itemSecondaryBgColor;
        background-color: var(--item-secondary-bg-color);
        border-bottom-style: solid;
        border-bottom-color: colors.$itemBorderColor;
        border-bottom-color: var(--item-border-color);
        border-bottom-width: 1px;
        color: colors.$lightTextColor;
        color: var(--light-text-color);
        padding-left: 8px;
        padding-right: 8px;

        .rest {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            flex-grow: 1;
        }

        .icon {
            display: flex;
            justify-content: center;
            @include mixins.up('sm') {
                justify-content: flex-end;
            }
            align-items: center;
            height: 50px;

            &.time {
                justify-content: flex-end;
                width: 25px;
                @include mixins.up('sm') {
                    width: 80px;
                    padding-right: 20px;
                }
            }

            &.symb {
                padding-left: 12px;
                padding-right: 8px;
                @include mixins.up('md') {
                    padding-left: 55px;
                    padding-right: 50px;
                    height: 50px;
                }
                align-self: flex-start;
                min-width: 48px;
                @include mixins.up('sm') {
                    min-width: 54px;
                }
            }

            &.temp {
                min-width: 40px;
                padding-right: 6px;
            }

            &.feelsLike {
                min-width: 40px;
                padding-right: 6px;
            }

            &.humidity {
                min-width: 40px;
                padding-right: 6px;
            }

            &.precipitation {
                min-width: 50px;
                @include mixins.up('sm') {
                    min-width: 60px;
                }
                justify-content: center;
            }

            &.winds {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-right: 4px;
                min-width: 50px;
                @include mixins.up('sm') {
                    min-width: 65px;
                }
            }
        }
    }

    .hourContainer {
        background-color: colors.$listBgColor;
        background-color: var(--list-bg-color);
        .hour {
            border-bottom-style: solid;
            border-bottom-color: colors.$itemBorderColor;
            border-bottom-color: var(--item-border-color);
            border-bottom-width: 1px;
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 8px;

            &.last {
                border-bottom-style: none;
            }

            .row {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }

            .dataRow {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
                align-items: center;
                flex-grow: 1;

                span {
                    text-align: center;
                }
            }

            div.time {
                width: 25px;
                @include mixins.up('sm') {
                    width: 80px;
                    padding-right: 20px;
                }
                text-align: end;
            }

            .symb {
                padding-left: 12px;
                padding-right: 8px;
                @include mixins.up('md') {
                    padding-left: 50px;
                    padding-right: 50px;
                }
                align-self: flex-start;

                img {
                    width: 28px;
                    height: 28px;
                    @include mixins.up('sm') {
                        width: 34px;
                        height: 34px;
                    }
                }
            }

            .t {
                min-width: 40px;
                font-size: 1.1rem;
            }

            .feelsLike {
                min-width: 40px;
            }

            .humidity {
                min-width: 40px;
            }

            .precipContainer {
                min-width: 50px;
                @include mixins.up('sm') {
                    min-width: 60px;
                }
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .precipitation {
                color: $rain;
            }

            .noPrecipitation {
                color: colors.$lightTextColor;
                color: var(--light-text-color);
            }

            .unit {
                font-size: 0.7rem;
            }

            .winds {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                min-width: 50px;
                @include mixins.up('sm') {
                    min-width: 65px;
                }

                .windSpeed {
                    min-width: 25px;
                    @include mixins.up('sm') {
                        min-width: 30px;
                    }
                    text-align: end;
                }

                img {
                    height: 25px;
                    width: 25px;
                }
            }

            .textRow {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                font-weight: 400;
                font-size: 0.8rem;

                .symbolText {
                    text-align: start;
                    padding-left: 12px;
                    padding-right: 8px;
                    @include mixins.up('md') {
                        padding-left: 50px;
                    }
                    color: colors.$lightTextColor;
                    color: var(--light-text-color);
                }

                .gust {
                    white-space: nowrap;
                    text-align: end;
                    flex-grow: 1;
                    color: colors.$lightTextColor;
                    color: var(--light-text-color);
                }
            }

            .precipChance {
                align-self: flex-end;
                color: colors.$lightTextColor;
                color: var(--light-text-color);
                font-weight: 400;
                font-size: 0.8rem;
            }
        }

        div.sunny {
            background: linear-gradient(90deg, rgba(254, 211, 127, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
        }
        div.cloudy {
            background: linear-gradient(90deg, rgba(232, 232, 232, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
        }
        div.drizzle {
            background: linear-gradient(90deg, rgba(90, 216, 228, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
        }
        div.rain {
            background: linear-gradient(90deg, rgba(159, 202, 230, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
        }
        div.heavyrain {
            background: linear-gradient(90deg, rgba(182, 206, 215, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
        }
        div.storm {
            background: linear-gradient(90deg, rgba(192, 157, 233, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
        }
        div.nightclear {
            background: linear-gradient(90deg, rgba(131, 165, 196, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
        }
        div.nightcloudy {
            background: linear-gradient(90deg, rgba(184, 194, 199, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
        }
        div.nightdrizzle {
            background: linear-gradient(90deg, rgba(79, 198, 195, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
        }
        div.nightrain {
            background: linear-gradient(90deg, rgba(118, 177, 206, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
        }
        div.nightheavyrain {
            background: linear-gradient(90deg, rgba(151, 187, 193, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
        }
        div.nightstorm {
            background: linear-gradient(90deg, rgba(157, 154, 204, 0.75) -10%, rgba(255, 255, 255, 0) 25%);
        }
    }
}
