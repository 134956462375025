@use '../../mixins';
@use '../colors';

.daily {
    h1 {
        padding-bottom: 8px;
    }

    .dayContainer {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 4px;
        background-color: colors.$bgColor;
        background-color: var(--bg-color);
    }
}
