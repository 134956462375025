@use '../../../mixins';
@use '../../colors';

.item.feedbackBar {
    order: 10;
    color: white;
    cursor: pointer;
    font-weight: 500;
    padding: mixins.spacing(0.5, 0.5);
    // margin: mixins.spacing(2, 0);

    @include mixins.up('sm') {
        display: none;
        margin: mixins.spacing(0, 0.5, 1, 0.5);
    }
    font-size: 0.95rem;
    background-color: colors.$headingBgColor;
    background-color: var(--heading-bg-color);

    p {
        text-align: center;
        position: relative;

        a {
            color: inherit;
            display: block;
            text-decoration: none;

            img {
                vertical-align: middle;

                &.right {
                    position: absolute;
                    right: 0.2rem;
                    top: 0.3rem;
                }
            }
        }
    }
}
