@use '../../mixins';
@use '../colors';

.observations {
    order: 8;

    .wrap {
        background-color: colors.$itemBgColor;
        background-color: var(--item-bg-color);
    }

    .observationsContainer {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        @include mixins.up('md') {
            flex-direction: row;
            justify-content: space-between;
        }
        padding-bottom: 8px;
        padding-top: 8px;

        .obsLink {
            display: flex;
            flex: 1;
            flex-grow: 1;
            color: inherit;
            text-decoration: none;
            padding: mixins.spacing(1, 1);
            border-bottom-style: solid;
            border-bottom-color: colors.$itemBorderColor;
            border-bottom-color: var(--item-border-color);
            border-bottom-width: 1px;

            @include mixins.up('md') {
                border-bottom-style: none;
                border-right-style: solid;
                border-right-color: colors.$itemBorderColor;
                border-right-color: var(--item-border-color);
                border-right-width: 1px;
            }

            &.last {
                border-bottom-style: none;
                border-right-style: none;
            }
        }

        .location {
            display: flex;
            flex: 1;
            flex-grow: 1;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            .locationName {
                display: block;
                height: 46px;
                align-self: start;
                padding: mixins.spacing(0.5, 0.5);
                p {
                    font-size: 14px;
                    font-weight: 500;
                }
            }

            .topRow {
                width: 100%;
                padding: mixins.spacing(0.5, 0.5);
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                font-size: 48px;
                font-weight: 200;
            }

            .symbolText {
                font-size: 1rem;
                font-weight: 500;
                align-self: flex-end;
                padding-right: 16px;
            }

            .data {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                padding: mixins.spacing(2, 2, 2, 2);

                .rightData {
                    text-align: right;
                }

                div.wind {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    flex-direction: row;

                    img {
                        margin-right: 0.5em;
                    }
                }

                p {
                    padding: mixins.spacing(0.5, 0);
                    color: colors.$lightTextColor;
                    color: var(--light-text-color);

                    span.value {
                        font-weight: 500;
                        color: colors.$textColor;
                        color: var(--text-color);
                    }
                }
            }

            .when {
                color: colors.$lightTextColor;
                color: var(--light-text-color);
                align-self: flex-end;
                padding-right: 16px;
            }
        }
    }
}
